import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { OverlayView } from '@react-google-maps/api';
import Map from './Map';
import CloseIcon from '../../images/icon-close.svg';

const MemberMarkerWrapper = styled.div`
  position: absolute;
  transform: translate(-20px, -40px);
  cursor: pointer;
  transition: transform 0.2s;
  &:hover {
    transform: translate(-20px, -40px) scale(1.1);
  }
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0 2px 6px rgba(0,0,0,0.3);
`;

const InfoWindowWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  border-radius: 10px;
  background: #FFF;
  padding: 12px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.3);
  min-width: 200px;
  transform: translate(-50%, calc(-100% - 15px));
  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
    transform: translateX(-50%);
  }
`;

function MemberInfoWindow({ member }) {
  return (
    <InfoWindowWrapper>
      <ProfileImage
        src={member.profilePicture}
        alt={`${member.firstName} ${member.lastName}`}
        onError={(e) => {
          e.target.src = '/default-avatar.png'; // Add fallback image
        }}
      />
      <Box>
        <Typography variant="subtitle1" fontWeight="600">
          {member.firstName}
          {' '}
          {member.lastName}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {member.position || 'Team Member'}
        </Typography>
      </Box>
    </InfoWindowWrapper>
  );
}

function MemberMarker({ member, onClick, isSelected }) {
  return (
    <OverlayView
      position={{ lat: member.lat, lng: member.lng }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <MemberMarkerWrapper onClick={onClick}>
        {isSelected ? (
          <MemberInfoWindow member={member} />
        ) : (
          <ProfileImage
            src={member.profilePicture}
            alt={`${member.firstName} ${member.lastName}`}
          />
        )}
      </MemberMarkerWrapper>
    </OverlayView>
  );
}

const CloseBtn = styled.div`
  background: white;
  filter: drop-shadow(0px 0.864px 3.458px rgba(0, 0, 0, 0.25));
  border-radius: 100px;
  padding: 10px;
  position: absolute;
  right: 33px;
  top: 33px;
  cursor: pointer;
`;

function MembersMap({ members, handleClose }) {
  const [selectedMember, setSelectedMember] = useState(null);
  const [mapCenter, setMapCenter] = useState(
    members.length > 0
      ? { lat: members[0].lat, lng: members[0].lng }
      : { lat: 0, lng: 0 },
  );

  const handleMarkerClick = (member) => {
    setSelectedMember(member.id);
    setMapCenter({ lat: member.lat, lng: member.lng });
  };

  return (
    <Box sx={{ position: 'relative', height: '100vh', width: '100%' }}>
      <Map lat={mapCenter.lat} lng={mapCenter.lng} style={{ height: '100%', width: '100%' }}>
        {members.map((member) => (
          <MemberMarker
            key={`member-${member.id}`}
            member={member}
            onClick={() => handleMarkerClick(member)}
            isSelected={selectedMember === member.id}
          />
        ))}
        <CloseBtn onClick={handleClose} data-testid="close-btn">
          <img src={CloseIcon} alt="close icon" />
        </CloseBtn>
      </Map>
    </Box>
  );
}

MembersMap.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      profilePicture: PropTypes.string.isRequired,
      position: PropTypes.string,
    }),
  ).isRequired,
  handleClose: PropTypes.func.isRequired,
};

MemberInfoWindow.propTypes = {
  member: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    profilePicture: PropTypes.string.isRequired,
    position: PropTypes.string,
  }).isRequired,
};

MemberMarker.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    profilePicture: PropTypes.string.isRequired,
    position: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default MembersMap;
