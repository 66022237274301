/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Route, Switch } from 'react-router-dom';
import './app.css';
import { PrivateRoute } from './components';
import { NotificationProvider } from './contexts/Notification';
import { UserProvider } from './contexts/User';
import TwoFactorAuthentication from './screen/TwoFactorAuthentication/index';
import Activity from './screen/activity';
import AWSSetupInstructions from './screen/awsSetupInstructions';
import Desktop from './screen/desktop';
import GeoDashboard from './screen/geoDashboard';
import InsiderThreats from './screen/insiderThreats';
import MemberThreats from './screen/insiderThreats/MemberThreats';
import InvalidInvitation from './screen/invalidInvitation';
import InvitationExpired from './screen/invitationExpired';
import Login from './screen/login';
import Members from './screen/members';
import OrganizationSignup from './screen/organizationSignup';
import ResetPassword from './screen/resetPassword';
import EmailForResetPassword from './screen/resetPassword/EmailForResetPassword';
import ScreenshotFrequency from './screen/screenshotFrequency';
import Settings from './screen/settings';
import Signup from './screen/signup';
import TeamEngagement from './screen/teamEngagement';
import TeamView from './screen/teamEngagement/TeamView';
import Timesheets from './screen/timesheets';
import ViewActivity from './screen/viewActivity';
import WebcamAudit from './screen/webcamAudit';
import Workspaces from './screen/workspaces';
import TimeWorked from './screen/reports';
import WebcamAudits from './screen/reports/WebcamAudits';
import GeoLocations from './screen/reports/GeoLocations';
import WorkspacesForSuperAdmin from './screen/workspacesForSuperadmin';
import OpenShootApp from './screen/workspaces/OpenShootApp';
import { PtoRequest, MembersPto } from './screen/pto';
import Download from './screen/download';
import Report from './screen/teamEngagement/Report';
import Dashboard from './screen/dashboard';
import Organizations from './screen/admin/organizations';
import Teams from './screen/admin/teams';
import Users from './screen/admin/users';
import Superadminworkspaces from './screen/admin/Superadminworkspaces';
import CreateOrganization from './screen/CreateOrganization';
import Homepage from './screen/homepage';
import Account from './screen/account';
import Payments from './screen/settings/payments';
import UserDashboard from './screen/userDashboard';
import OrganizationChart from './screen/organizationChart';
import Alerts from './screen/alerts';
import TeamSettings from './screen/teamSettings';
import ProfileUpdateRequest from './screen/profileUpdateRequest';
import GeoMap from './screen/geoMap';
import Acknowledge from './screen/alerts/Acknowledgement';

class App extends PureComponent {
  render() {
    const { hostname } = window.location;
    const publicHostnames = process.env.REACT_APP_PUBLIC_HOSTNAMES.split(',');
    const appHostnames = process.env.REACT_APP_APP_HOSTNAMES.split(',');
    const isDev = process.env.REACT_APP_NODE_ENV === 'development';
    const isHomePageMode = isDev && process.env.REACT_APP_ENABLE_HOMEPAGE_ON_LOCAL;

    const homeRoutes = (
      <Route exact path="/" component={Homepage} />
    );

    const appRoutes = [
      // Public Routes
      <Route exact path="/" component={Login} key="/" />,
      <Route exact path="/signup" component={Signup} key="/signup" />,
      <Route exact path="/alert-acknowledgement" component={Acknowledge} key="/alert-acknowledgement" />,
      <Route exact path="/desktop" component={Desktop} key="/desktop" />,
      <Route exact path="/dashboard" component={UserDashboard} accountLevel={2} key="/dashboard" />,
      <Route exact path="/organization-chart" component={OrganizationChart} accountLevel={2} key="/organization-chart" />,
      <Route exact path="/profile-update-requests" component={ProfileUpdateRequest} accountLevel={2} key="/profile-update-requests" />,
      <Route exact path="/download" component={Download} key="/download" />,
      <Route exact path="/organization-signup" component={OrganizationSignup} key="/organization-signup" />,
      <Route exact path="/organization-signup/aws-setup" component={AWSSetupInstructions} key="/organization-signup/aws-setup" />,
      <Route exact path="/reset-password" component={ResetPassword} key="/reset-password" />,
      <Route exact path="/reset-password/email" component={EmailForResetPassword} key="/reset-password/email" />,
      <Route exact path="/twoFactor" component={TwoFactorAuthentication} key="/twoFactor" />,
      <Route exact path="/workspaces" component={Workspaces} key="/workspaces" />,
      <Route exact path="/invalid-invitation" component={InvalidInvitation} key="/invalid-invitation" />,
      <Route exact path="/invitation-expired" component={InvitationExpired} key="/invitation-expired" />,
      <Route exact path="/account" component={Account} key="/account" />,
      <Route exact path="/workspace" component={OpenShootApp} key="/workspace" />,
      <Route exact path="/pto/requests" component={PtoRequest} key="/pto/requests" />,
      <Route exact path="/workspaces-for-superadmin" component={WorkspacesForSuperAdmin} key="/workspaces-for-superadmin-1" />,
      <Route exact path="/workspaces-for-superadmin" component={WorkspacesForSuperAdmin} key="/workspaces-for-superadmin-2" />,
      <Route exact path="/admin/dashboard" component={Dashboard} key="/admin/dashboard" />,
      <Route exact path="/admin/organizations" component={Organizations} key="/admin/organizations" />,
      <Route exact path="/admin/workspaces" component={Superadminworkspaces} key="/admin/workspaces" />,
      <Route exact path="/admin/teams" component={Teams} key="/admin/teams" />,
      <Route exact path="/admin/users" component={Users} key="/admin/users" />,
      <Route exact path="/admin/organization/create" component={CreateOrganization} key="/admin/organization/create" />,
      <Route exact path="/members/alerts" component={Alerts} key="/members/alerts" />,

      // Private Routes
      <PrivateRoute exact path="/members/manage" component={Members} key="members-manage" />,
      <PrivateRoute exact path="/members/timesheets" component={Timesheets} key="members-timesheets" />,
      <PrivateRoute exact path="/members/activity" component={Activity} key="members-activity" />,
      <PrivateRoute exact path="/members/screenshotFrequency" component={ScreenshotFrequency} key="screenshot-frequency" />,
      <PrivateRoute exact path="/members/webcamAudit" component={WebcamAudit} key="webcam-audit" />,
      <PrivateRoute exact path="/members/activity/view" component={ViewActivity} key="view-activity" />,
      <PrivateRoute exact path="/members/team-engagement" component={TeamEngagement} accountLevel={2} key="team-engagement" />,
      <PrivateRoute exact path="/geoDashboard" component={GeoDashboard} key="geo-dashboard" />,
      <PrivateRoute exact path="/geoMap" component={GeoMap} key="/geoMap" />,
      <PrivateRoute exact path="/members/:memberId/location-violation-history" component={GeoDashboard} key="geo-location-history" />,
      <PrivateRoute exact path="/members/team-engagement/:teamId" component={TeamView} accountLevel={2} key="team-engagement-view" />,
      <PrivateRoute exact path="/members/team-engagement/:teamId/member/:memberId" component={Report} accountLevel={2} key="team-engagement-member-report" />,
      <PrivateRoute exact path="/insiderThreats" component={InsiderThreats} key="insider-threats" />,
      <PrivateRoute exact path="/insiderThreats/:memberId" component={MemberThreats} key="member-threats" />,
      <PrivateRoute exact path="/reports/time-worked" component={TimeWorked} key="time-worked-report" />,
      <PrivateRoute exact path="/reports/webcam-results" component={WebcamAudits} key="webcam-results-report" />,
      <PrivateRoute exact path="/reports/geo-results" component={GeoLocations} key="geo-results-report" />,
      <PrivateRoute exact path="/admin/settings" component={Settings} key="admin-settings" />,
      <PrivateRoute exact path="/admin/team-settings" component={TeamSettings} key="admin-team-settings" />,
      <PrivateRoute exact path="/pto/members" component={MembersPto} accountLevel={2} key="pto-members" />,
      <PrivateRoute exact path="/admin/settings/payments" component={Payments} key="admin-settings-payments" />,
    ];

    return (
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <UserProvider>
          <NotificationProvider>
            <Switch>
              {(isHomePageMode || (!isDev && publicHostnames.includes(hostname))) ? (
                homeRoutes
              ) : (isDev || appHostnames.includes(hostname)) && (
                appRoutes
              )}
            </Switch>
          </NotificationProvider>
        </UserProvider>
      </GoogleOAuthProvider>
    );
  }
}

export default App;
