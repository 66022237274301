/* eslint-disable no-nested-ternary */
import React, {
  useContext, useEffect, useState, useCallback,
} from 'react';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import { styled } from '@mui/system';
import { Chip } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import { Navbar, Sidebar } from '../../components';
import { UserContext } from '../../contexts/User';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import useDebounce from '../../hooks/useDebounce';
import CustomTable from '../../components/table/CustomTable';
import MemberNameEmailColumn from '../../components/table/MemberNameEmailColumn';
import EditColumn from './EditColumn';
import ResolveModal from '../../components/LocationHistory/ResolveModal';
import Pagination from '../../components/pagination';

const CustomTableStyles = {
  container: {
    marginTop: 1,
  },
  header: {
    backgroundColor: 'var(--dark-10)',
    fontSize: '14px',
    '.MuiTableSortLabel-iconDirectionAsc': {
      transform: 'rotate(0deg) !important',
    },
    '&:nth-of-type(2)': {
      textAlign: 'center',
    },
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff !important',
    },
    '&:hover': {
      backgroundColor: 'var(--dark-10) !important',
    },
  },
  cell: {
    button: {
      textWrap: 'nowrap',
      color: 'var(--dark-2)',
      borderColor: 'var(--dark-4) !important',
      '&:disabled': {
        color: 'var(--dark-5)',
        borderColor: 'var(--dark-7) !important',
        cursor: 'not-allowed',
        pointerEvents: 'all !important',
      },
    },
    '&:nth-of-type(2)': {
      textAlign: 'center',
    },
  },
};

const TABLE_HEADERS = [
  {
    label: 'User',
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'name',
    colSpan: 1.75,
  },
  {
    label: 'Threats',
    colSpan: 0.75,
  },
  {
    label: 'Last Issue Date',
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'issueDate',
    colSpan: 1,
  },
  {
    label: '',
    colSpan: 0.5,
  },
];

const getColorStyles = (type) => {
  switch (type) {
    case 'danger':
      return {
        backgroundColor: 'rgba(221, 73, 73, 0.2)',
        color: 'rgba(221, 73, 73, 1)',
      };
    default:
      return {
        backgroundColor: 'rgba(83, 165, 81, 0.2)',
        color: 'rgba(83, 165, 81, 1)',
      };
  }
};

const StyledChip = styled(Chip)(({ type }) => {
  const { backgroundColor, color } = getColorStyles(type);

  return {
    backgroundColor,
    color,
    fontWeight: 500,
    width: 'max-content',
  };
});

const mapToRow = (member, history, setIsModalOpen, setSelectedMember) => {
  if (!member) {
    return;
  }
  const getChipLabelType = (totalViolations) => {
    if (!totalViolations) {
      return {
        chipLabel: 'None',
        chipType: '',
      };
    }

    return {
      chipLabel: `${totalViolations} Unresolved`,
      chipType: 'danger',
    };
  };

  const totalViolations = (member?.locationViolations?.length || 0) + (member?.webcamViolations?.length || 0);
  const { chipLabel, chipType } = getChipLabelType(totalViolations);

  return ({
    user: <MemberNameEmailColumn
      profilePicture={member?.user?.profilePicture}
      firstName={member.haveJoined ? member?.user?.firstName : ''}
      lastName={member.haveJoined ? member?.user?.lastName : ''}
      email={member?.user?.email}
      component="span"
    />,
    unresolved: <StyledChip
      component="span"
      label={chipLabel}
      type={chipType}
    />,
    lastIssueDate: member?.latestViolationDate
      ? moment(member?.latestViolationDate).format('hh:MM A · MM/DD/YYYY')
      : 'None',
    actions: <EditColumn options={[
      {
        label: 'View Issues',
        action: () => history.push(`/insiderThreats/${member.id}`),
        enabled: true,
      },
      {
        label: 'Resolve All Open Issues',
        action: () => {
          setIsModalOpen(true);
          setSelectedMember(member);
        },
        enabled: true,
      },
    ]}
    />,
  });
};

function InsiderThreats() {
  const { search } = useLocation();
  const { activeWorkspace } = useContext(UserContext);
  const query = new URLSearchParams(search);
  const page = query.get('page');
  const [currentPage, setCurrentPage] = useState(page ? parseInt(page, 10) : 1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCounts, setTotalCounts] = useState(0);
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { debounce } = useDebounce();
  const history = useHistory();

  const mapToRowFn = (items) => mapToRow(items, history, setIsModalOpen, setSelectedMember);

  const getMembersInsiderThreats = async (params = {}) => {
    setIsLoading(true);
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.membersInsiderThreats(activeWorkspace.id),
      params: {
        name: searchQuery,
        sortKey: 'name',
        sortOrder: 'ASC',
        pageSize,
        page: currentPage,
        ...params,
      },
      timeout: 30000,
    });
    if (response.success) {
      setMembers(response.data?.members ? response.data?.members.map(mapToRowFn) : []);
      setTotalPages(response.data?.totalPages ?? 1);
      setTotalCounts(response.data?.totalCounts ?? 0);
      setIsLoading(false);

      if (response.data?.totalPages < currentPage) {
        setCurrentPage(1);
      }
    }
  };

  const resolveAll = useCallback(async () => {
    await sendRequest({
      method: 'put', url: API.WORKSPACE.resolveMemberThreats(activeWorkspace.id, selectedMember.id),
    });
    await getMembersInsiderThreats({
      sortKey: 'name',
      sortOrder: 'ASC',
    });
  }, [activeWorkspace, selectedMember]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    history.push(`?page=${page}`);
  };

  const handlePageSizeChange = (pageSize) => {
    setCurrentPage(1);
    setTotalPages(Math.ceil(totalCounts / pageSize));
    setPageSize(pageSize);
  };

  useEffect(() => {
    getMembersInsiderThreats({
      sortKey: 'name',
      sortOrder: 'ASC',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  const handleSort = debounce(async (sortKey, sortOrder) => {
    setIsLoading(true);
    return getMembersInsiderThreats({
      sortKey,
      sortOrder,
    });
  }, 250);

  const handleSearch = debounce(async (query) => {
    setSearchQuery(query);
    return getMembersInsiderThreats({
      name: query,
    });
  }, 500);

  return (
    <>
      <Navbar />
      <Sidebar />
      <div className="screen-wrapper">
        <div className="screen-header">
          <div className="screen-header-container d-flex justify-content-between position-relative">
            <div className="w-100 d-flex justify-content-between">
              <h3 className="header-manage-member w-100" style={{ fontWeight: 600 }}>Insider Threats</h3>
              <div className="searchBox">
                <input type="text" placeholder="Search for user" onChange={(e) => handleSearch(e.target.value)} />
                <i className="fa fa-search searchIcon" />
              </div>
            </div>
          </div>
          <p className="main-sub-text">View location and webcam audit violations</p>
          {/* Insert components for the page here, to the right of the sidebar */}
          <CustomTable
            headers={TABLE_HEADERS}
            items={members}
            loading={isLoading}
            handleSort={handleSort}
            styles={CustomTableStyles}
          />
          {isModalOpen && (
          <ResolveModal
            closeModal={() => setIsModalOpen(false)}
            handleResolve={() => resolveAll()}
            isAll
          />
          )}
        </div>
      </div>
      <Pagination
        currentPage={currentPage}
        pageSize={pageSize}
        totalPages={totalPages}
        totalCounts={totalCounts}
        onPageChange={handlePageChange}
        setPageSize={handlePageSizeChange}
      />
    </>
  );
}

export default InsiderThreats;
