import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Dialog, DialogContent,
} from '@mui/material';
import { CalendarIcon, AlertIcon } from '../../components/Icon';
import RefreshIcon from '../../images/refresh.svg';
import { formatDate } from '../../utils/time';
import AlertDescription from './partials/AlertDescription';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';

function ViewIssueModal({
  selectedAlert, isOpen, handleClose,
}) {
  const { member } = useContext(UserContext);
  const [alert, setAlert] = useState(selectedAlert);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    setAlert({
      ...selectedAlert,
    });
  }, [selectedAlert]);

  const handleSendAcknowledgement = async () => {
    setIsUpdating(true);
    const response = await sendRequest({
      method: 'put',
      url: API.WORKSPACE.sendAcknowledgement(selectedAlert?.id),
      data: { managerId: member.id },
    });
    setIsUpdating(false);
    if (response.success) {
      setAlert({ ...alert, status: 'AcknowledgementSent' });
    }
  };

  const alertActionText = (status) => {
    switch (status) {
      case 'Pending':
        return 'Please send an acknowledgement to resolve this alert.';
      case 'AcknowledgementSent':
        return 'Acknowledgement has been sent to the member.';
      case 'Resolved':
        return 'This alert has been resolved.';
      default:
        return '';
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="sm"
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '586px',
          margin: 'auto',
        },
      }}
      fullWidth
    >
      <DialogContent>
        <div className="alert-modal-container">
          <div className="d-flex align-c gap-2 mb-4">
            <span className="alert-status-badge" data-status={alert?.status}>
              {`${alert?.alertType?.category} Bot Alert - ${alert?.status}`}
            </span>
            <span className="alert-date-badge">
              <CalendarIcon width="20" height="20" />
              {alert?.createdAt ? formatDate(alert?.createdAt) : ''}
            </span>
          </div>
          <div className="d-flex align-c gap-3 mb-3">
            <div className="alert-avatar">
              {alert?.member?.user?.profilePicture ? (
                <img
                  src={alert?.member?.user?.profilePicture}
                  alt={alert?.member?.user?.fullName}
                  className="alert-avatar"
                />
              ) : (
                <span className="alert-avatar-letter">
                  {alert?.member?.user?.firstName.slice(0, 1)}
                  {alert?.member?.user?.lastName.slice(0, 1)}
                </span>
              )}
            </div>
            <div className="alert-user-info">
              <h5 className="alert-user-name">{alert?.member?.user?.fullName}</h5>
              <div className="d-flex gap-2">
                <Link
                  to={`/members/alerts/${alert?.memberId}`}
                  className="alert-modal-view-btn"
                >
                  View Alerts
                </Link>
                <Link
                  to={`/members/activity/view?member=${alert?.memberId}`}
                  className="alert-modal-view-btn"
                >
                  View Engagement
                </Link>
              </div>
            </div>
          </div>
          <div className="d-flex space-between pb-4 border-bottom-div">
            {/* <div> */}
            {/*  <div className="alert-user-info-title mb-1">Role</div> */}
            {/*  <div className="alert-user-info-content">{alert?.member?.user?.role}</div> */}
            {/* </div> */}
          </div>
          <div className="pt-4">
            <div className="d-flex gap-2 mb-3">
              <AlertDescription alert={alert} />
            </div>
            <div className="d-flex gap-2 mb-3">
              <div className="alert-icon p-2" style={{ backgroundColor: '#FCECE7' }}>
                <AlertIcon width="24" height="24" color="#EB6240" />
              </div>
              <div>
                <div className="alert-user-info-title mb-1">
                  Alert Action
                </div>
                <div className="alert-description-text">
                  {alertActionText(alert?.status)}
                </div>
              </div>
            </div>
          </div>
          {alert?.status === 'Resolved' && alert?.acknowledgements?.[0]?.acknowledgementUndertaken?.[0] && (
          <div className="d-flex pt-4">
            <a
              className="alert-resend-btn"
              href={alert.acknowledgements[0].acknowledgementUndertaken}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Acknowledgement
            </a>
          </div>
          )}

          {alert?.status === 'AcknowledgementSent' && (
            <div className="d-flex pt-4">
              <button className="alert-resend-btn" type="button">
                <img src={RefreshIcon} alt="refresh" style={{ height: 20 }} />
                Resend Acknowledgement
              </button>
            </div>
          )}
          {alert?.status === 'Pending' && (
            <div className="d-flex pt-4">
              <button
                className="alert-mark-btn"
                type="button"
                disabled={isUpdating}
                onClick={handleSendAcknowledgement}
              >
                {!isUpdating ? 'Send Acknowledgement' : 'Updating...'}
              </button>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

ViewIssueModal.propTypes = {
  selectedAlert: PropTypes.shape({
    id: PropTypes.number.isRequired,
    acknowledgements: PropTypes.arrayOf(PropTypes.shape({
      acknowledgedByManager: PropTypes.bool,
      managerAckTime: PropTypes.instanceOf(Date),
    })),
    status: PropTypes.string.isRequired,
    alertType: PropTypes.shape({
      category: PropTypes.string.isRequired,
      triggerCondition: PropTypes.string.isRequired,
    }),
    createdAt: PropTypes.instanceOf(Date).isRequired,
    member: PropTypes.shape({
      user: PropTypes.shape({
        profilePicture: PropTypes.string,
        fullName: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        role: PropTypes.string,
      }),
    }),
    memberId: PropTypes.number.isRequired,

  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ViewIssueModal;
