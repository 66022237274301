import React from 'react';
import { Navbar, Sidebar } from '../../components';
import ProfileUpdateRequestList from './list';

function ProfileUpdateRequests() {
  return (
    <>
      <Navbar />
      <Sidebar />
      <div className="screen-wrapper">
        <div className="screen-header insights">
          <div className="d-flex align-c width-full justify-content-sm-between mb-4">
            <h3 className="header-manage-member">Members Profile Update Requests</h3>
          </div>
          <ProfileUpdateRequestList />
        </div>
      </div>
    </>
  );
}

export default ProfileUpdateRequests;
