import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  Button,
  CircularProgress,
  Typography,
  Box,
  Paper,
  Grid,
  Avatar,
  Divider,
  useTheme,
} from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import { UserContext } from '../../contexts/User';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import AlertDescription from './partials/AlertDescription';

function AcknowledgeForm() {
  const theme = useTheme();
  const history = useHistory();
  const { member } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [alertData, setAlertData] = useState(null);
  const [error, setError] = useState(null);
  const signaturePad = useRef(null);
  const location = useLocation();
  const pageRef = useRef(null);

  const fetchAlert = async (token) => {
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.getAlertByToken(member.workspaceId, token),
    });

    if (response.success) setAlertData(response.data);
  };

  // Generate PDF from the page
  const generatePDF = async () => {
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF('p', 'mm', 'a4');
    const canvas = await html2canvas(pageRef.current, { scale: 2 });

    const imgData = canvas.toDataURL('image/png');
    const imgWidth = 210;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
    return pdf;
  };

  // Handle acknowledgment submission
  const handleAcknowledge = async () => {
    if (signaturePad.current.isEmpty()) {
      setError('Please provide a signature before acknowledging.');
      return;
    }

    setIsLoading(true);
    try {
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get('token');
      const pdf = await generatePDF();
      const formData = new FormData();
      formData.append('alertId', alertData.id);
      formData.append('status', 'Acknowledged');
      formData.append('token', token);

      const pdfBlob = pdf.output('blob');
      formData.append('acknowledgementFile', pdfBlob, `Acknowledgement_${alertData?.id}.pdf`);

      const response = await sendRequest({
        method: 'put',
        url: API.WORKSPACE.submitAcknowledgementResponse(alertData.workspaceId, alertData.id),
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 30000,
      });

      if (response.success) {
        setAlertData({ ...alertData, status: 'Acknowledged' });
      }
    } catch (err) {
      setError('Something went wrong.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    if (!token) {
      history.push('/dashboard');
      return;
    }

    fetchAlert(token);
  }, [location, history]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      p={3}
      bgcolor={theme.palette.grey[50]}
    >
      <Paper
        ref={pageRef} // Capture the entire page for PDF
        elevation={3}
        sx={{
          padding: 4,
          width: '100%',
          maxWidth: 680,
          borderRadius: 4,
          position: 'relative',
          overflow: 'visible',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: -24,
            left: '50%',
            transform: 'translateX(-50%)',
            bgcolor: theme.palette.error.light,
            color: 'white',
            px: 3,
            py: 1,
            borderRadius: 3,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <WarningIcon fontSize="small" />
          <Typography variant="subtitle1">ACTION REQUIRED</Typography>
        </Box>

        {isLoading && (
          <Box display="flex" justifyContent="center" p={4}>
            <CircularProgress size={40} />
          </Box>
        )}

        {error && (
          <Box
            bgcolor={theme.palette.error.light}
            color={theme.palette.error.contrastText}
            p={2}
            mb={3}
            borderRadius={2}
            display="flex"
            alignItems="center"
            gap={1}
          >
            <WarningIcon />
            <Typography variant="body2">{error}</Typography>
          </Box>
        )}

        {!alertData && (
        <Grid item xs>
          <Typography variant="h5" fontWeight={600}>
            Token is invalid or expired.
          </Typography>
        </Grid>
        )}

        {alertData && (
          <>
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <Avatar
                  src={alertData.member.user.profilePicture}
                  sx={{
                    width: 80,
                    height: 80,
                    border: `3px solid ${theme.palette.primary.main}`,
                  }}
                />
              </Grid>
              <Grid item xs>
                <Typography variant="h5" fontWeight={600}>
                  {alertData.member.user.firstName}
                  {' '}
                  {alertData.member.user.lastName}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {alertData.member.accountType}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {alertData.member.user.email}
                </Typography>
              </Grid>
            </Grid>

            <Divider sx={{ my: 3, borderColor: theme.palette.divider }} />

            <AlertDescription alert={alertData} />

            <Typography
              variant="caption"
              color="text.secondary"
              display="block"
              textAlign="right"
            >
              {new Date(alertData.createdAt).toLocaleString()}
            </Typography>

            <Box mb={4}>
              <Typography variant="subtitle1" gutterBottom>
                Digital Signature
                <Typography variant="caption" color="text.secondary" ml={1}>
                  (Sign within the box below)
                </Typography>
              </Typography>

              <Paper
                variant="outlined"
                sx={{
                  borderColor: theme.palette.divider,
                  borderRadius: 2,
                  overflow: 'hidden',
                }}
              >
                <SignatureCanvas
                  ref={signaturePad}
                  onEnd={() => setError(null)}
                  penColor="blue"
                  canvasProps={{
                    width: 615,
                    height: 150,
                    className: 'signature-canvas',
                  }}
                />
              </Paper>
            </Box>

            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Button
                variant="text"
                color="inherit"
                startIcon={<ClearIcon />}
                onClick={() => signaturePad.current.clear()}
                sx={{ textTransform: 'none' }}
              >
                Clear Signature
              </Button>

              {alertData.status === 'Resolved' ? (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled
                >
                  Acknowledged
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleAcknowledge}
                  disabled={isLoading}
                  startIcon={!isLoading && <CheckCircleIcon />}
                  sx={{
                    px: 4,
                    borderRadius: 2,
                    textTransform: 'none',
                    boxShadow: theme.shadows[2],
                    '&:hover': {
                      boxShadow: theme.shadows[4],
                      bgcolor: theme.palette.success.main,
                    },
                  }}
                >
                  {isLoading ? (
                    <>
                      <CircularProgress size={20} sx={{ mr: 2 }} />
                      Processing...
                    </>
                  ) : (
                    'Acknowledge Alert'
                  )}
                </Button>
              )}
            </Box>
          </>
        )}
      </Paper>
    </Box>
  );
}

export default AcknowledgeForm;
