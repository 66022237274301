import React, {
  useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Box, Button, MenuItem, Select, InputLabel,
} from '@mui/material';
import MultiSelect from '../../../components/muiCustomized/MultiSelect';
import closeImg from '../../../images/close.svg';
import { BootstrapInput } from '../../../components/muiCustomized/TextInput';

function EditModal({
  closeModal,
  teams,
  users,
  selectedEmail,
  onSaved,
}) {
  const [emailName, setEmailName] = useState();

  const [assignedTeams, setAssignedTeams] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [sendFrequency, setSendFrequency] = useState(-1);
  const frequency = ['Daily', 'Weekly', 'Monthly'];

  useEffect(() => {
    if (selectedEmail.id === -1) return;

    setEmailName(selectedEmail.emailSubject);
    setSendFrequency(selectedEmail.sendFrequency);
    const combinedRecipients = selectedEmail.recipients.split(',');
    const combinedTeams = selectedEmail.teams.split(',');

    const updatedUsers = [...assignedUsers];
    combinedRecipients.forEach((item) => {
      const newUser = {
        id: updatedUsers.length + 1,
        name: users[users.findIndex((u) => u.id === parseInt(item, 10))].name,
      };
      updatedUsers.push(newUser);
    });
    setAssignedUsers(updatedUsers);

    const updatedTeams = [...assignedTeams];
    combinedTeams.forEach((item) => {
      const newTeam = {
        id: updatedTeams.length + 1,
        name: teams[teams.findIndex((u) => u.id === parseInt(item, 10))].name,
      };
      updatedTeams.push(newTeam);
    });
    setAssignedTeams(updatedTeams);
  }, [selectedEmail]);

  const handleTeamsChanges = (newValue) => {
    setAssignedTeams(newValue);
  };

  const handleUsersChanges = (newValue) => {
    setAssignedUsers(newValue);
  };

  const handleFrequencyChanges = (value) => {
    setSendFrequency(frequency.indexOf(value));
  };

  return (
    <Modal open onClose={closeModal} className="flex-row align-c jstfy-c create-email-modal">
      <Box class="create-modal">
        <Box className="modal-body">
          <Box className="title">
            <Box className="text">
              Create New Email
            </Box>
            {/* eslint-disable jsx-a11y/click-events-have-key-events */}
            {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
            <img
              className="img"
              src={closeImg}
              onClick={closeModal}
              alt=""
            />
          </Box>
          <Box className="sub-option">
            <Box className="title">Email Name</Box>
            <BootstrapInput
              className="input"
              value={emailName}
              sx={{
                '& .MuiInputBase-input': {
                  border: '1.5px solid',
                  borderColor: 'var(--sds-input-border)',
                },
              }}
              onChange={(e) => setEmailName(e.target.value)}
              placeholder="Required"
            />
          </Box>
          <Box className="sub-option">
            <Box className="title">Recipients: Team/Teams</Box>
            <MultiSelect
              className="multi-select"
              options={teams}
              value={assignedTeams}
              handleChange={handleTeamsChanges}
              placeholder={assignedTeams.length ? '' : 'Optional'}
            />
          </Box>
          <Box className="sub-option">
            <Box className="title">Recipients: Individual</Box>
            <MultiSelect
              className="multi-select"
              options={users}
              value={assignedUsers}
              handleChange={handleUsersChanges}
              placeholder={assignedUsers.length ? '' : 'Optional'}
            />
          </Box>
          <Box className="sub-option">
            <Box className="title">Recipients: Individual</Box>
            <Select
              className="select"
              value={sendFrequency > -1 ? frequency[sendFrequency] : ''}
              onChange={(e) => handleFrequencyChanges(e.target.value)}
              renderValue={(selected) => {
                if (!selected) {
                  return (
                    <InputLabel
                      sx={{
                        fontSize: '16px',
                        color: '#212529',
                        lineHeight: '20px',
                        opacity: '40%',
                        marginBottom: '0px',
                      }}
                    >
                      Required
                    </InputLabel>
                  );
                }
                return (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    { selected }
                  </Box>
                );
              }}
              displayEmpty
            >
              {frequency && frequency.map((period, index) => (
                <MenuItem className="menu-item" key={index} value={period}>
                  {period}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>

        <Box className="modal-footer">
          <Button
            className="cancelBtn"
            variant="outlined"
            onClick={closeModal}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            className="composeBtn"
            variant="contained"
            onClick={() => {
              onSaved(emailName, assignedTeams, assignedUsers, sendFrequency);
              closeModal();
            }}
          >
            Compose Email
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

EditModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })),
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })),
  selectedEmail: PropTypes.shape({
    id: PropTypes.number.isRequired,
    emailId: PropTypes.number.isRequired,
    isEnabled: PropTypes.number.isRequired,
    emailSubject: PropTypes.string.isRequired,
    sendFrequency: PropTypes.number.isRequired,
    teams: PropTypes.string.isRequired,
    recipients: PropTypes.string.isRequired,
  }),
  onSaved: PropTypes.func.isRequired,
};

export default EditModal;
