import moment from 'moment-timezone';
import React from 'react';
import PropTypes from 'prop-types';
import Snapshot from './Snapshot';
import { ActivityType } from '../../propTypes';

function SnapshotRow({ hourBlock, snapshots, blurScreenshots }) {
  return (
    <div className="snapshot-row">
      <div style={{ marginLeft: -19, display: 'flex', gap: 10 }}>
        <div className="time-bubble" />
        <p className="snap-text">{moment().set({ hours: hourBlock, minutes: 0 }).format('h:mm A')}</p>
      </div>
      <div className="small-snapshot-grid snapshot-grid">
        {snapshots.map((snapshot, index) => (snapshot
          ? <Snapshot key={snapshot?.data?.id} snapshot={snapshot} blurScreenshots={blurScreenshots} />
          : <div key={index} className="no-activity-placeholder">No Activity</div>))}
      </div>
    </div>
  );
}

export default SnapshotRow;

SnapshotRow.propTypes = {
  hourBlock: PropTypes.string.isRequired,
  snapshots: PropTypes.arrayOf(PropTypes.shape({
    data: ActivityType,
    localBlockTime: PropTypes.string.isRequired,
    localStartTime: PropTypes.string.isRequired,
    roundedEndTime: PropTypes.string.isRequired,
    roundedStartTime: PropTypes.string.isRequired,
  })),
  blurScreenshots: PropTypes.bool,
};
