import React, { useContext, useEffect, useState } from 'react';
import { Navbar, Sidebar } from '../../components';
import GeoMapComponent from './GeoMapComponent';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';
import { dummyUserImage } from '../../utils/system';
import SelectInput from '../../components/muiCustomized/CustomMenuSelect';

function ProfileUpdateRequests() {
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [members, setMembers] = useState([]);
  const { activeWorkspace } = useContext(UserContext);

  const transformMemberData = (originalData) => {
    try {
      return {
        id: originalData?.id || 0,
        lat: Number(originalData.user?.userLocation?.latitude) || 0,
        lng: Number(originalData.user?.userLocation?.longitude) || 0,
        firstName: originalData.user?.firstName || 'Unknown',
        lastName: originalData.user?.lastName || 'User',
        profilePicture:
            typeof originalData?.user?.profilePicture === 'string'
            && originalData.user.profilePicture.trim() !== ''
              ? originalData.user.profilePicture
              : dummyUserImage,
        position: originalData?.accountType || 'Team Member',
      };
    } catch (error) {
      console.error('Error transforming member data:', error);
      return null;
    }
  };

  const fetchGeoMapMembers = async () => {
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.geoMapMembers(activeWorkspace.id),
      params: {
        teamId: selectedTeam,
      },
    });

    if (response.success) {
      const members = response.data.members.map(transformMemberData);
      setMembers(members);
    }
  };

  const fetchTeams = async () => {
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.getTeams(activeWorkspace.id),
    });

    if (response.success) {
      const teams = response.data.teams.map((team) => ({
        id: team.id,
        value: team.id,
        text: team.name,
      }));
      setTeams(teams);
    }
  };

  useEffect(() => {
    fetchTeams();
  }, []);

  useEffect(() => {
    fetchGeoMapMembers();
  }, [selectedTeam]);

  return (
    <>
      <Navbar />
      <Sidebar />
      <div className="screen-wrapper">
        <div className="screen-header insights">
          <div className="d-flex align-c width-full justify-content-sm-between mb-4">
            <div className="org-page-caption">
              Workspace Geo Map
            </div>
            <div className="flex-row gap-2">
              <SelectInput
                id="team"
                menuItems={teams}
                menuWidth="202px"
                placeholder="Select Team"
                value={selectedTeam}
                handleForm={(e) => setSelectedTeam(e.target.value)}
              />
            </div>
          </div>
          <GeoMapComponent
            members={members}
            handleClose={() => console.log('Close map')}
          />
        </div>
      </div>
    </>
  );
}

export default ProfileUpdateRequests;
