import React, {
  useState, useContext, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Box, Button, TextField, MenuItem,
} from '@mui/material';
import { TextInput } from '../../../components/muiCustomized/TextInput';
import MultiSelect from '../../../components/muiCustomized/MultiSelect';
import Toggle from '../../../components/Toggle';
import { sendRequest } from '../../../api/shootAPI';
import API from '../../../api/endpoints';
import { NotificationContext } from '../../../contexts/Notification';
import { screenshotsPerPeriodMapping, blurScreenshotsMapping } from '../../../constants/screenshotsPerPeriod';

function EditModal({
  member,
  closeModal,
  onMemberSaved,
  workspaceId,
  teams,
  ptoPolicies,
}) {
  const { displayNotification } = useContext(NotificationContext);
  const [isSaving, setIsSaving] = useState(false);
  const [designation, setDesignation] = useState(member?.designation ?? '');
  const [assignedTeams, setAssignedTeams] = useState([]);
  const [currentTeams, setCurrentTeams] = useState([]);
  const [screenshotFrequency, setScreenshotFrequency] = useState(member.screenshotsPerPeriod);
  const [blurScreenshots, setBlurScreenshots] = useState(member.blurScreenshots);
  const [webcamAuditsPerPeriod, setWebcamAuditsPerPeriod] = useState(member.webcamAuditsPerPeriod);
  const [ptoPolicy, setPtoPolicy] = useState(member.offTimePolicies[0]?.id);
  const [formErrors, setFormErrors] = useState({});

  const isAssignedTeamsChanged = () => {
    let isEqual = currentTeams.every((team) => assignedTeams.some((obj) => obj.id === team.id));
    if (isEqual) {
      isEqual = assignedTeams.every((team) => currentTeams.some((obj) => obj.id === team.id));
    }
    return !isEqual;
  };

  const isChanged = useMemo(() => {
    const isTeamsChanged = isAssignedTeamsChanged();
    return isTeamsChanged || designation !== member.designation
      || screenshotFrequency !== member.screenshotsPerPeriod
      || blurScreenshots !== member.blurScreenshots
      || webcamAuditsPerPeriod !== member.webcamAuditsPerPeriod
      || ptoPolicy !== member.offTimePolicies[0]?.id;
  }, [designation, assignedTeams, screenshotFrequency, blurScreenshots, webcamAuditsPerPeriod, ptoPolicy]);

  useEffect(() => {
    const memberTeams = member.teamMates.map((teamMate) => (
      { id: teamMate.team.id, name: teamMate.team.name }
    ));
    setAssignedTeams(memberTeams);
    setCurrentTeams(memberTeams);
  }, [member]);

  const handleTeamsChanges = (newValue) => {
    setAssignedTeams(newValue);
  };

  const handleSaveMember = async () => {
    setIsSaving(true);
    const params = {};
    if (isAssignedTeamsChanged()) params.assignedTeams = assignedTeams;
    if (designation !== member?.designation) params.designation = designation;
    if (screenshotFrequency !== member.screenshotsPerPeriod) params.screenshotsPerPeriod = screenshotFrequency;
    if (blurScreenshots !== member.blurScreenshots) params.blurScreenshots = blurScreenshots;
    if (webcamAuditsPerPeriod !== member.webcamAuditsPerPeriod) {
      params.webcamAuditsPerPeriod = webcamAuditsPerPeriod;
    }
    if (ptoPolicy !== member?.offTimePolicies[0]?.id) params.ptoPolicy = ptoPolicy;
    const { data, success } = await sendRequest({
      method: 'put',
      url: API.WORKSPACE.memberManagement(workspaceId, member.id),
      data: {
        ...params,
      },
    });
    if (success) {
      onMemberSaved();
      closeModal();
      displayNotification({
        message: 'Changes saved successfully.',
        severity: 'success',
      });
    } else {
      setFormErrors(data);
      displayNotification({
        message: 'Error occurs while saving changes.',
        severity: 'error',
      });
    }
    setIsSaving(false);
  };

  return (
    <Modal open onClose={closeModal} className="flex-row align-c jstfy-c create-team-modal">
      <Box style={{
        width: 489,
        padding: 32,
        background: '#fff',
        borderRadius: 8,
      }}
      >
        <div className="modal-header">
          <h5 className="m-b16 text-600">
            Edit
            {' '}
            {member?.user?.fullName}
          </h5>
        </div>
        <div className="modal-body p-0">
          <div className="input-wrapper">
            <TextInput
              label="Designation"
              value={designation}
              onChange={(e) => setDesignation(e.target.value)}
              placeholder="Required"
              error={!!formErrors?.designation}
              helperText={formErrors?.designation}
            />
          </div>
          <div className="input-wrapper mb-3">
            <div className="modal-input-label mb-1">Teams Assigned</div>
            <MultiSelect
              options={teams}
              value={assignedTeams}
              handleChange={handleTeamsChanges}
              size="medium"
              sx={{
                '&.MuiAutocomplete-inputRoot': {
                  paddingTop: '3px',
                },
              }}
            />
          </div>
          <div className="size-xs mb-3">
            <div className="modal-input-label mb-2">
              Control the numbers of screenshots taken in a 10 minute period
            </div>
            <Toggle
              activeLabel={Object.keys(screenshotsPerPeriodMapping).find(
                (key) => screenshotsPerPeriodMapping[key] === screenshotFrequency,
              )}
              options={[
                { label: 'None', onClick: () => { setScreenshotFrequency(0); } },
                { label: '1x', onClick: () => { setScreenshotFrequency(1); } },
                { label: '2x', onClick: () => { setScreenshotFrequency(2); } },
                { label: '3x', onClick: () => { setScreenshotFrequency(3); } },
              ]}
            />
          </div>
          <div className="size-xs mb-3">
            <div className="modal-input-label mb-2">
              Automatically blur screenshots upon capture for every individual
            </div>
            <Toggle
              activeLabel={Object.keys(blurScreenshotsMapping).find(
                (key) => blurScreenshotsMapping[key] === blurScreenshots,
              )}
              options={[
                { label: 'Off', onClick: () => { setBlurScreenshots(false); } },
                { label: 'On', onClick: () => { setBlurScreenshots(true); } },
              ]}
            />
          </div>
          <div className="size-xs mb-3">
            <div className="modal-input-label mb-2">
              Control the webcam audit for the users
            </div>
            <Toggle
              activeLabel={webcamAuditsPerPeriod}
              options={[
                { label: 'None', onClick: () => { setWebcamAuditsPerPeriod('None'); } },
                { label: 'Hourly', onClick: () => { setWebcamAuditsPerPeriod('Hourly'); } },
                { label: 'Daily', onClick: () => { setWebcamAuditsPerPeriod('Daily'); } },
                { label: 'Weekly', onClick: () => { setWebcamAuditsPerPeriod('Weekly'); } },
              ]}
            />
          </div>
          <div className="size-xs mb-3">
            <div className="modal-input-label mb-2">
              PTO Policy
            </div>
            <TextField
              select
              fullWidth
              value={ptoPolicy}
              onChange={(value) => setPtoPolicy(value)}
              variant="outlined"
              size="small"
            >
              {
                  ptoPolicies.map((policy) => (
                    <MenuItem value={policy.id}>{policy.name}</MenuItem>
                  ))
                }
            </TextField>
          </div>
        </div>

        <div className="modal-footer border-0 p-0 mt-2">
          <Button
            sx={{
              marginRight: 2,
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              padding: '12px 16px 12px 16px',
            }}
            variant="outlined"
            onClick={closeModal}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            sx={{
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              padding: '12px 16px 12px 16px',
            }}
            variant="contained"
            onClick={handleSaveMember}
            disabled={isSaving || !isChanged}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {isSaving ? 'Saving...' : 'Save Changes'}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

EditModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onMemberSaved: PropTypes.func.isRequired,
  member: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    workspaceId: PropTypes.number.isRequired,
    designation: PropTypes.string.isRequired,
    screenshotsPerPeriod: PropTypes.number.isRequired,
    webcamAuditsPerPeriod: PropTypes.string.isRequired,
    blurScreenshots: PropTypes.bool.isRequired,
    offTimePolicies: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })),
    teamMates: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
    })),
    user: PropTypes.shape({
      fullName: PropTypes.string,
    }),
  }),
  workspaceId: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  teams: PropTypes.object,
  ptoPolicies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })),
};

export default EditModal;
