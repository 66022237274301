import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import ImageViewer from 'react-simple-image-viewer';
import { Popover, Switch, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ActivityType } from '../../propTypes';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { getActivityColor } from '../../utils/activity';

function Snapshot({ snapshot, blurScreenshots }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const memberId = queryParams.get('member');
  const {
    screenshots, activityPercentage, secondsElapsed, mousePercentage, keyboardPercentage,
    meetingPercentage,
  } = snapshot.data;

  const [isBlur, setIsBlur] = useState(blurScreenshots);
  const [screensAreOpen, setScreensAreOpen] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);

  useEffect(() => {
    if (screenshots.length) {
      const fetchImages = async () => {
        const urls = await Promise.all(
          screenshots.map(async (screenshot) => {
            const { success, data } = await sendRequest({
              method: 'GET',
              url: API.MEMBER.screenshot(memberId),
              params: { baseUrl: screenshot.url },
            });
            return success ? data.url : null;
          }),
        );
        setImageUrls(urls.filter(Boolean));
      };
      fetchImages();
    }
  }, [screenshots, memberId]);

  return (
    <>
      <article className="snapshot flex-col">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          style={{
            position: 'relative', height: '100%', width: '100%', background: 'var(--dark-5)',
          }}
          className="snapshot-img-view flex-row align-c jstfy-c"
          onClick={() => setScreensAreOpen(true)}
          role="button"
          tabIndex={0}
        >
          <div className="snapshot-img-cover" />
          {imageUrls.length ? (
            <div style={{ position: 'relative', width: '100%' }}>
              <img
                src={imageUrls[0]}
                width="100%"
                alt="Activity screenshot"
                className={isBlur ? 'snapshot-img-blur' : ''}
                style={{ borderRadius: '4px 4px 0 0', minHeight: 68 }}
              />
              <div style={{
                position: 'absolute',
                bottom: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                color: 'white',
                padding: '2px 8px',
                fontSize: 12,
                borderRadius: '4px',
              }}
              >
                {screenshots.length}
                {' '}
                Screens
              </div>
            </div>
          ) : (
            <p>No Screenshots</p>
          )}
        </div>

        <div className="snapshot-details" style={{ marginTop: 'auto' }}>
          <div className="snap-text">
            {snapshot.roundedStartTime}
            {' '}
            -
            {' '}
            {snapshot.roundedEndTime}
          </div>
          <div
            className="activity-bar-container"
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <div
              className="activity-bar-parent"
            >
              <div
                className="activity-bar"
                style={{
                  width: `${activityPercentage}%`,
                  // eslint-disable-next-line no-nested-ternary,max-len
                  background: activityPercentage > 50 ? '#64BA62' : activityPercentage > 20 ? '#F6C643' : '#EE7C5C',
                }}
              />
            </div>
          </div>
          <div className="snap-text">
            {`${activityPercentage}% of ${secondsElapsed < 60
              ? `${secondsElapsed} seconds`
              : `${Math.round(secondsElapsed / 60)} minutes`}`}
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <Switch size="small" checked={isBlur} onChange={() => setIsBlur(!isBlur)} color="secondary" />
            <span className="snap-text">Blur Screenshot</span>
          </div>
        </div>

        <Popover
          id={`activity-popover-${snapshot.data.id}`}
          sx={{
            pointerEvents: 'none',
            '& .MuiPaper-root': {
              backgroundColor: '#1E1E1E',
              color: 'white',
              borderRadius: '6px',
              minWidth: '120px',
              textAlign: 'center',
              p: 1,
              boxShadow: '0 2px 6px rgba(255,255,255,0.15)',
            },
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={handlePopoverClose}
          disablePortal
          disableEnforceFocus
          disableRestoreFocus
        >
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 0.3, fontSize: '10px' }}>
              Breakdown
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontWeight: 'bold', color: getActivityColor(activityPercentage), fontSize: '12px' }}
            >
              {activityPercentage}
              %
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: 0.5,
                mt: 0.3,
                p: 0.3,
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                borderRadius: '3px',
              }}
            >
              <Typography variant="caption" sx={{ fontSize: '9px', fontWeight: 500, marginRight: 1 }}>
                🖱️
                {' '}
                {mousePercentage}
                %
              </Typography>
              <Typography variant="caption" sx={{ fontSize: '9px', fontWeight: 500, marginRight: 1 }}>
                ⌨️
                {' '}
                {keyboardPercentage}
                %
              </Typography>
              <Typography variant="caption" sx={{ fontSize: '9px', fontWeight: 500 }}>
                📅
                {' '}
                {meetingPercentage}
                %
              </Typography>
            </Box>
          </Box>
        </Popover>
      </article>

      {screensAreOpen && (
        <ImageViewer
          disableScroll
          src={imageUrls}
          currentIndex={0}
          onClose={() => setScreensAreOpen(false)}
          backgroundStyle={{ backgroundColor: 'rgba(0,0,0,0.9)', zIndex: 20 }}
          closeOnClickOutside
        />
      )}
    </>
  );
}

export default Snapshot;

Snapshot.propTypes = {
  snapshot: PropTypes.shape({
    data: ActivityType,
    localBlockTime: PropTypes.string.isRequired,
    localStartTime: PropTypes.string.isRequired,
    roundedEndTime: PropTypes.string.isRequired,
    roundedStartTime: PropTypes.string.isRequired,
  }),
  blurScreenshots: PropTypes.bool,
};
