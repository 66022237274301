import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Modal, Button, Box } from '@mui/material';
import { UserContext } from '../../contexts/User';
import { Images } from '../../common';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';

const InfoMessage = styled.div`
  background-color: var(--dark-8);
  border-radius: 4px;
  padding: 7px 8px;
  font-size: 14px;
  color: var(--blue-2);

  img {
    margin-bottom: 1px;
  }
`;

function DeleteMemberConfirmationModal({ closeModal, getMembers, member }) {
  const { activeWorkspace } = useContext(UserContext);
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const handleDeleteMember = async () => {
    const response = await sendRequest({
      method: 'put',
      url: API.WORKSPACE.deactivateMember(activeWorkspace.id, member.id),
    });
    if (response.success) {
      const name = member?.user?.firstName && member?.user?.lastName
        ? `${member.user.firstName} ${member.user.lastName}`
        : member.user.email;
      setSuccessMessage(`Deactivated ${name}`);
      getMembers();
      setTimeout(() => {
        closeModal();
      }, 2000);
    } else {
      setErrorMessage(response?.data?.error || 'Something went wrong. Please try again.');
    }
  };

  return (
    <Modal open onClose={closeModal} className="flex-row align-c jstfy-c" data-testid="delete-member-modal">
      <Box style={{
        width: 482,
        padding: 32,
        background: '#fff',
        borderRadius: 8,
      }}
      >
        <h5 className="m-b20 text-600" data-testid="modal-title">Delete Workspace Member</h5>
        <p className="m-t4 m-b16" data-testid="info-text">
          After you deactivate the account the user will no longer be able to log in to the workplace.
        </p>
        <InfoMessage data-testid="info-section">
          <img src={Images.infoIcon} className="m-r8" alt="info icon" />
          User will no longer be able to log in to the workplace.
        </InfoMessage>
        <div className="newMemberForm-btns" style={{ marginTop: 40 }}>
          <Button
            onClick={closeModal}
            color="secondary"
            sx={{ marginRight: 2 }}
            data-testid="cancel"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteMember}
            disabled={successMessage}
            data-testid="delete-member"
          >
            Deactivate
          </Button>
        </div>
        <div className="success-msg">{successMessage}</div>
        {errorMessage && <div className="error-msg">{errorMessage}</div>}
      </Box>
    </Modal>
  );
}

DeleteMemberConfirmationModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  getMembers: PropTypes.func.isRequired,
  member: PropTypes.shape({
    id: PropTypes.number.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }).isRequired,
    activity: PropTypes.shape({}),
  }).isRequired,
};

export default DeleteMemberConfirmationModal;
