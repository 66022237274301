import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Grid, InputLabel, Button, Box, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { TextInput } from '../../components/muiCustomized/TextInput';
import AvatarUploader from '../../components/avatarUploader';
import DatePicker from '../../components/datePicker';
import NavigationLayout from '../../components/NavigationLayout';
import API from '../../api/endpoints';
import { sendRequest } from '../../api/shootAPI';
import { UserContext } from '../../contexts/User';
import { NotificationContext } from '../../contexts/Notification';
import ChangePasswordModal from './ChangePasswordModal';
import FaceRegister from './FaceRegister';
import AddressUpdateModal from './AddressUpdateModal';

const _ = require('lodash');

const CustomBox = styled(Box)`
  width: 168px;
  height: 168px;
  border-radius: 8px;
`;

function Account() {
  const { user, setUserAccount } = useContext(UserContext);
  const { displayNotification } = useContext(NotificationContext);
  const history = useHistory();
  const [formData, setFormData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    birthday: user.birthday ? new Date(user.birthday) : null,
    avatar: user.profilePicture,
  });
  const [formErrors, setFormErrors] = useState({});
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showFaceIdSetupModal, setShowFaceIdSetupModal] = useState(false);
  const [showAddressUpdateModal, setShowAddressUpdateModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [faceIdDataPath, setFaceIdDataPath] = useState('');
  const [location, setLocation] = useState({
    description: user.userLocation?.formattedAddress,
    latitude: user.userLocation?.latitude,
    longitude: user.userLocation?.longitude,
  });
  const [profileUpdateRequests, setProfileUpdateRequests] = useState(null);

  const fetchFaceIdData = async () => {
    const { success, data } = await sendRequest({
      method: 'get',
      url: API.USER.fetchFaceIdData,
    });
    if (success) {
      if (data.faceIdData) {
        setFaceIdDataPath(data.faceIdData.image_path);
      }
      if (data.location) {
        setLocation(data.location);
      }
      if (data.profileUpdateRequests) {
        const keyedProfileUpdateRequests = _.keyBy(data.profileUpdateRequests, 'profile_field');
        setProfileUpdateRequests(keyedProfileUpdateRequests);
      }
    }
  };

  useEffect(() => {
    fetchFaceIdData();
  }, []);

  const handleFormData = (e) => {
    if ((e.target.name === 'firstName' || e.target.name === 'lastName') && e.target.value.length > 255) {
      displayNotification({
        message: 'The name must be less than 255 characters.',
        severity: 'error',
      });
      return;
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSaveChanges = async () => {
    setSaving(true);
    setFormErrors({});
    const _formData = new FormData();
    Object.keys(formData).forEach((field) => _formData.append(field, formData[field]));
    const response = await sendRequest({
      method: 'put',
      url: API.USER.account,
      params: { userId: user.id },
      data: _formData,
    });
    if (response.success) {
      const { avatar, userToken } = response.data;
      setFormData({ ...formData, avatar });
      setUserAccount(userToken);
      displayNotification({
        message: 'The account is updated successfully.',
        severity: 'success',
      });
    } else {
      setFormErrors(response.data);
    }
    setSaving(false);
  };

  return (
    <div>
      {showChangePasswordModal
        && (
          <ChangePasswordModal
            email={user.email}
            closeModal={() => setShowChangePasswordModal(false)}
          />
        )}

      {showFaceIdSetupModal ? (
        <FaceRegister
          closeModal={() => setShowFaceIdSetupModal(false)}
          setPath={fetchFaceIdData}
        />
      ) : null }

      {showAddressUpdateModal ? (
        <AddressUpdateModal
          closeModal={() => setShowAddressUpdateModal(false)}
          setData={fetchFaceIdData}
        />
      ) : null }

      <NavigationLayout title="My Account">
        <Grid container spacing={3} style={{ width: '100%', paddingTop: '10px' }}>
          <Grid item xs={6}>
            <Grid
              container
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              style={{ width: '524px', paddingTop: '10px' }}
            >
              <Grid item xs={6}>
                <TextInput
                  name="firstName"
                  label="First Name"
                  value={formData.firstName}
                  onChange={handleFormData}
                  placeholder="Required"
                  error={!!formErrors?.firstName}
                  helperText={formErrors?.firstName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  name="lastName"
                  label="Last Name"
                  value={formData.lastName}
                  onChange={handleFormData}
                  placeholder="Required"
                  error={!!formErrors?.lastName}
                  helperText={formErrors?.lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  name="email"
                  label="Email"
                  type="email"
                  value={formData.email}
                  onChange={handleFormData}
                  placeholder="Required"
                  error={!!formErrors?.email}
                  helperText={formErrors?.email}
                  disabled
                />
              </Grid>
              <Grid item xs={6} sx={{ position: 'relative' }}>
                <Button
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    padding: 0,
                    zIndex: 1,
                  }}
                  onClick={() => setShowChangePasswordModal(true)}
                >
                  <EditOutlinedIcon color="success" sx={{ fontSize: '20px', marginRight: '8px' }} />
                  <p
                    style={{
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: 'var(--green-3)',
                      textTransform: 'none',
                    }}
                  >
                    Edit
                  </p>
                </Button>
                <TextInput
                  label="Password"
                  value="********"
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <div style={{ marginBottom: '16px' }}>
                  <InputLabel
                    sx={{ fontSize: '18px', color: '#212529', margin: 0 }}
                    shrink
                    htmlFor="date-of-birth"
                  >
                    Date of Birth
                  </InputLabel>
                  <div className="custom-date-icon">
                    <DatePicker
                      name="birthday"
                      format="MM/dd/yyyy"
                      id="date-of-birth"
                      oneTap
                      value={formData.birthday}
                      onChange={(date) => setFormData({ ...formData, birthday: date })}
                      placeholder="Required"
                      error={formErrors?.birthday ? true : undefined}
                      // helperText={formErrors?.birthday}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <InputLabel
                  sx={{ fontSize: '18px', color: '#212529', margin: 0 }}
                  shrink
                  htmlFor="profile-picture"
                >
                  Profile Picture
                </InputLabel>
                <AvatarUploader
                  defaultAvatar={formData.avatar}
                  onChange={(avatar) => setFormData({ ...formData, avatar })}
                  id="profile-picture"
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: '20px' }}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => history.goBack()}
                  sx={{
                    fontSize: '16px',
                    height: '40px',
                    marginRight: '8px',
                    border: 'inset',
                    borderWidth: 1,
                    borderColor: '#7D8698',
                    padding: '12px 24px',
                    textTransform: 'capitalize',
                  }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSaveChanges}
                  sx={{
                    fontSize: '16px',
                    height: '40px',
                    padding: '12px 24px',
                    backgroundColor: '#429256',
                    textTransform: 'capitalize',
                  }}
                >
                  { saving ? 'Saving...' : 'Save Changes' }
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid item xs={12} sx={{ position: 'relative' }}>
              <Button
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  padding: 0,
                  zIndex: 1,
                }}
                onClick={() => setShowAddressUpdateModal(true)}
              >
                <EditOutlinedIcon color="success" sx={{ fontSize: '20px', marginRight: '8px' }} />
                <p
                  style={{
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: 'var(--green-3)',
                    textTransform: 'none',
                  }}
                >
                  Edit
                </p>
              </Button>
              <TextInput
                label="Current Address"
                value={location.description || ''}
                disabled
              />
            </Grid>

            {profileUpdateRequests?.address?.new_value?.formattedAddress && (
              <Grid item xs={12}>
                <InputLabel
                  sx={{
                    fontSize: '18px',
                    color: '#212529',
                    margin: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                  shrink
                  htmlFor="new-requested-address"
                >
                  New Requested Address
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: '#FF5722',
                      marginLeft: '8px',
                      textAlign: 'right',
                    }}
                  >
                    Changes will apply after approval.
                  </Typography>
                </InputLabel>
                <TextInput
                  id="new-requested-address"
                  value={profileUpdateRequests.address.new_value.formattedAddress}
                  disabled
                />
              </Grid>
            )}

            <Grid container spacing={2}>
              {/* First Face ID Block */}
              <Grid item xs={6}>
                <InputLabel
                  sx={{
                    fontSize: '18px', color: '#212529', margin: 0, position: 'relative',
                  }}
                  shrink
                  htmlFor="face-id-1"
                >
                  Current Face ID
                  <Button
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      left: 100,
                      padding: 0,
                      zIndex: 1,
                    }}
                    onClick={() => setShowFaceIdSetupModal(true)}
                  >
                    <EditOutlinedIcon color="success" sx={{ fontSize: '20px', marginRight: '8px' }} />
                    <p
                      style={{
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: 'var(--green-3)',
                        textTransform: 'none',
                      }}
                    >
                      Edit
                    </p>
                  </Button>
                </InputLabel>

                {faceIdDataPath ? (
                  <CustomBox className="avatar">
                    <img src={faceIdDataPath} width="100%" height="100%" alt="avatar" />
                  </CustomBox>
                ) : (
                  <CustomBox
                    onClick={() => { setShowFaceIdSetupModal(true); }}
                    sx={{
                      border: '1px dashed var(--dark-4)',
                      padding: '51px 12px 0 12px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      gap: '8px',
                      cursor: 'pointer',
                    }}
                  >
                    <FileUploadOutlinedIcon sx={{ color: '#00155A' }} />
                    <p
                      style={{
                        fontSize: '10px',
                        lineHeight: '12px',
                        color: '#131414',
                        fontWeight: 'bold',
                        marginBottom: '-8px',
                      }}
                    >
                      <span>Setup face ID</span>
                    </p>
                  </CustomBox>
                )}
              </Grid>

              {/* Requested Face ID Block */}
              {profileUpdateRequests?.face_id?.new_value && (
              <Grid item xs={6}>
                <InputLabel
                  sx={{ fontSize: '18px', color: '#212529', margin: 0 }}
                  shrink
                  htmlFor="face-id-2"
                >
                  Requested Face ID
                </InputLabel>
                <CustomBox className="avatar">
                  <img
                    src={profileUpdateRequests.face_id.new_value.image_path}
                    width="100%"
                    height="100%"
                    alt="avatar"
                  />
                </CustomBox>
                {/* Information about manager approval */}
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: '#FF5722',
                    marginLeft: '8px',
                  }}
                >
                  Changes will apply after approval.
                </Typography>
              </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </NavigationLayout>
    </div>
  );
}

export default Account;
