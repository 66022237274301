import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Popover } from '@mui/material';
import API from '../../api/endpoints';
import { NotificationContext } from '../../contexts/Notification';
import { UserContext } from '../../contexts/User';
import { sendRequest } from '../../api/shootAPI';

function UserEditColumn({
  member, deactivateMember, editMember, style,
}) {
  const { activeWorkspace, user } = useContext(UserContext);
  const { displayNotification } = useContext(NotificationContext);

  const [userEditAnchorEl, setUserEditAnchorEl] = useState(null);

  const openUserEdit = Boolean(userEditAnchorEl);
  const userEditId = openUserEdit ? 'simple-popover' : undefined;

  const resendInvitation = async () => {
    const response = await sendRequest({
      method: 'post',
      url: API.WORKSPACE.resendInvitation(activeWorkspace.id, member.userId),
      data: {
        owner: user,
      },
    });
    if (response.success) {
      displayNotification({
        message: 'Resend invitation successfully.',
        severity: 'success',
      });
    } else {
      displayNotification({
        message: 'There was an issue resending invitation. Please try again',
        severity: 'error',
      });
    }
  };

  if (!member.status) return null;

  return (
    <div style={style}>
      <button
        type="button"
        label="edit-dropdown-button"
        onClick={(e) => setUserEditAnchorEl(e.currentTarget)}
      >
        <i className="fa fa-ellipsis-h dropdown-options" />
      </button>
      <Popover
        id={userEditId}
        open={openUserEdit}
        anchorEl={userEditAnchorEl}
        onClose={() => setUserEditAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        { !member.haveJoined
        && (
        <MenuItem
          onClick={() => { resendInvitation(member); setUserEditAnchorEl(null); }}
          data-testid="resend-invitation"
        >
          Resend Invitation
        </MenuItem>
        )}
        <MenuItem
          onClick={() => { editMember(member); setUserEditAnchorEl(null); }}
          data-testid="edit-user"
        >
          Edit User
        </MenuItem>
        <MenuItem
          sx={{ color: '#B0361A' }}
          onClick={() => {
            deactivateMember(member);
            setUserEditAnchorEl(null);
          }}
          data-testid="deactivate-user"
        >
          Deactivate User
        </MenuItem>
      </Popover>
    </div>
  );
}

export default UserEditColumn;

UserEditColumn.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
    status: PropTypes.bool.isRequired,
    accountType: PropTypes.string.isRequired,
    haveJoined: PropTypes.bool.isRequired,
    user: PropTypes.shape({
      firstName: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.oneOf([null]),
      ]),
      lastName: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.oneOf([null]),
      ]),
      email: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  deactivateMember: PropTypes.func.isRequired,
  editMember: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
};
