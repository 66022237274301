import * as React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultiSelect({
  options, value, handleChange, label = '', placeholder = '', size = 'small', props,
}) {
  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      value={value}
      size={size}
      onChange={(event, newValue) => {
        const isSelectAll = newValue.some((item) => item.id === 0);
        if (isSelectAll) handleChange([{ id: 0, name: 'All' }]);
        else handleChange(newValue);
      }}
      getOptionDisabled={(option) => {
        const isSelectedAll = value.some((item) => item.id === 0);
        return (option.id > 0 || option.id !== 0) && isSelectedAll;
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option, { selected }) => {
        // eslint-disable-next-line react/prop-types
        const { key, ...optionProps } = props;
        return (
        // eslint-disable-next-line react/jsx-props-no-spreading
          <li key={key} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <TextField {...params} label={label} placeholder={placeholder} fullWidth />
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

MultiSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  props: PropTypes.object,
};
