import React, { useState, useContext } from 'react';
import { Button, Modal } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { NotificationContext } from '../../contexts/Notification';
import PlacesAutocomplete from '../../components/PlacesAutocomplete';

function AddressUpdateModal({ closeModal, setData }) {
  const [saving, setSaving] = useState(false);
  const { displayNotification } = useContext(NotificationContext);
  const [address, setAddress] = useState({
    location: { description: '' },
    latlng: { lat: '', lng: '' },
  });

  const handleSubmit = async () => {
    setSaving(true);

    try {
      const response = await sendRequest({
        method: 'post',
        url: API.USER.updateAddressRequest,
        data: address,
      });
      if (response.success) {
        setData(response.data.profileUpdateRequest);
        displayNotification({
          message: response.data.message,
          severity: 'success',
        });
        closeModal();
      } else {
        displayNotification({
          message: response.data.error,
          severity: 'error',
        });
      }
    } catch (error) {
      displayNotification({
        message: error.response.data.error,
        severity: 'error',
      });
    }

    setSaving(false);
  };

  return (
    <Modal
      open
      onClose={closeModal}
      className="flex-row align-c jstfy-c"
      data-testid="face-id-setup"
    >
      <Box style={{
        width: 500,
        padding: 32,
        background: '#fff',
        borderRadius: 8,
      }}
      >
        <h5 className="m-b20 text-600" data-testid="modal-title">Request Address Change</h5>
        <PlacesAutocomplete
          location={address.location}
          onChange={setAddress}
          textfieldType="standard"
          label="Home Address"
          placeholder="Enter Home Address"
        />

        <Button
          variant="contained"
          color="success"
          onClick={handleSubmit}
          sx={{ marginRight: 2, width: '100px' }}
          disabled={saving || address.location.description === ''}
        >
          { saving ? 'Saving...' : 'Save' }
        </Button>
        <Button
          onClick={closeModal}
          color="secondary"
          variant="outlined"
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
}

AddressUpdateModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
};

export default AddressUpdateModal;
