import React, { useState } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TableBody,
  TableSortLabel,
  Typography,
  Pagination,
  CircularProgress,
} from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Umbrella from '../../images/umbrella-icon.svg';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const TableBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  // minHeight: 700,
}));

function CustomTable({
  headers,
  items,
  loading,
  pages = 1,
  currentPage = 1,
  handlePageChange,
  handleSort,
  styles = {},
  noItemText = 'There are no data yet',
}) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (header, sortKey) => {
    const determineOrder = orderBy === header && order === 'asc' ? 'desc' : 'asc';
    setOrder(determineOrder);
    setOrderBy(header);
    handleSort(sortKey, determineOrder);
  };

  const pageChange = (event, value) => handlePageChange(value);

  return (
    <TableContainer
      component={TableBox}
      sx={{ justifyContent: 'space-between', ...styles.container }}
    >
      <Table sx={{ minWidth: 700, ...styles.table }} stickyHeader size="small" aria-label="customized table">
        <TableHead>
          <TableRow>
            {
              headers.map(({
                label, sortable, sortKey, colSpan = 1,
              }) => (
                <TableCell
                  key={label}
                  sx={{ width: `calc((${colSpan}/${headers.length}) * 100%)`, ...styles.header }}
                >
                  {sortable ? (
                    <TableSortLabel
                      onClick={() => handleRequestSort(label, sortKey)}
                      active={orderBy === label}
                      direction={orderBy === label ? order : 'asc'}
                      // eslint-disable-next-line max-len, no-nested-ternary
                    >
                      {label}
                    </TableSortLabel>
                  ) : label}
                </TableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading && items && items.map((row, index) => (
            <StyledTableRow key={`tableRow-${index}`} sx={{ ...styles.row }}>
              {Object.keys(row).map((key) => (
                <TableCell key={key} sx={{ ...styles.cell }}>
                  {row[key]}
                </TableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
        {
          loading && (
            <Box component="caption" sx={{ position: 'absolute', left: '58vw', top: '50vh' }}>
              <CircularProgress data-testid="spinner" />
            </Box>
          )
        }
      </Table>
      {!loading && (!items || items?.length === 0) && (
        <Box sx={{ alignSelf: 'center', position: 'absolute', top: '50%' }}>
          <img src={Umbrella} alt="no-items" style={{ margin: '0 auto', display: 'block' }} />
          <Typography sx={{ fontStyle: 'italic' }}>
            {noItemText}
          </Typography>
        </Box>
      )}

      {!loading && pages > 1 && (
        <Pagination
          sx={{ borderTop: '1px solid var(--dark-7)', paddingTop: '8px' }}
          count={pages}
          page={currentPage}
          onChange={pageChange}
        />
      )}
    </TableContainer>
  );
}

CustomTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    sortable: PropTypes.bool,
    sortIcon: PropTypes.shape({}),
    colSpan: PropTypes.number,
  })),
  items: PropTypes.arrayOf(PropTypes.shape({
    member: PropTypes.string,
    policyName: PropTypes.string,
    reason: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    hours: PropTypes.number,
    status: PropTypes.string,
    requestDate: PropTypes.string,
    action: PropTypes.element,
  })),
  loading: PropTypes.bool,
  pages: PropTypes.number,
  currentPage: PropTypes.number,
  handlePageChange: PropTypes.func,
  handleSort: PropTypes.func,
  styles: PropTypes.shape({
    container: PropTypes.shape({}),
    table: PropTypes.shape({}),
    header: PropTypes.shape({}),
    row: PropTypes.shape({}),
    cell: PropTypes.shape({}),
  }),
  noItemText: PropTypes.string,
};

export default CustomTable;
