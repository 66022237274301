import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { InfoIcon } from '../../../components/Icon';
import './alert-description-style.css';
import GoogleMapWithDirections from '../../../components/googleMap/GoogleMapWithDirections';

const alertTypeConstants = {
  MISSED_WORK_START_TIME: 'Missed Work Start Time',
  PTO_RETURN_DELAY: 'PTO Return Delay',
  UNSCHEDULED_ABSENCE: 'Unscheduled Absence',
  OUTDATED_SHOOT_VERSION: 'Outdated Shoot Version',
  SHOOT_NOT_INSTALLED: 'Shoot Not Installed',
  PROHIBITED_APPLICATION_USAGE: 'Prohibited Application Usage',
  OUTSIDE_HOME_OFFICE: 'Outside Home Office',
  UNRECOGNIZED_FACIAL_RECOGNITION: 'Unrecognized Facial Recognition',
  NOT_FOUND_FACIAL_ID: 'Not Found Facial ID',
  LOCATION_VIOLATION: 'Location Violation',
};

function AlertDescription({ alert }) {
  const [showUTC, setShowUTC] = useState(false);

  const formatTime = (timeString) => {
    if (!timeString) return 'N/A';

    try {
      // Handle pure time strings (HH:mm:ss format)
      if (/^\d{2}:\d{2}:\d{2}$/.test(timeString)) {
        const today = moment().format('YYYY-MM-DD');
        const timeMoment = moment.utc(`${today}T${timeString}`);

        return showUTC
          ? `${timeMoment.format('HH:mm:ss')} UTC`
          : timeMoment.local().format('HH:mm:ss');
      }

      // Handle full ISO datetime strings
      const parsedTime = moment.utc(timeString);
      return showUTC
        ? `${parsedTime.format('HH:mm:ss')} UTC`
        : parsedTime.local().format('HH:mm:ss');
    } catch (e) {
      console.error('Error formatting time:', timeString, e);
      return 'Invalid Time';
    }
  };

  const calculateDelay = () => {
    try {
      if (!alert?.metadata?.firstActivityTimeOfTheDay || !alert?.metadata?.workingDayStartTimeOfTheMember) {
        return 'N/A';
      }

      const firstActivity = moment.utc(alert.metadata.firstActivityTimeOfTheDay, 'YYYY-MM-DD HH:mm:ss');

      const workingStart = moment
        .utc(`${firstActivity.format('YYYY-MM-DD')} 
        ${alert.metadata.workingDayStartTimeOfTheMember}`, 'YYYY-MM-DD HH:mm:ss');

      if (!firstActivity.isValid() || !workingStart.isValid()) return 'N/A';

      const diffMinutes = firstActivity.diff(workingStart, 'minutes');

      return `${diffMinutes} minutes`;
    } catch (e) {
      console.error('Error calculating delay:', e);
      return 'N/A';
    }
  };

  return (
    <div style={{ width: '-webkit-fill-available' }} className="alert-content-section">
      <div className="d-flex align-items-start">
        <div className="alert-icon p-2" style={{ backgroundColor: '#FEFBE7', flexShrink: 0 }}>
          <InfoIcon width="24" height="24" color="#F19E41" />
        </div>

        <div className="flex-grow-1">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4 className="alert-section-title m-0">Description / Alert Triggered</h4>
            <button
              type="button"
              onClick={() => setShowUTC(!showUTC)}
              className="timezone-toggle-btn"
            >
              {showUTC ? 'Switch to Local Time' : 'Switch to UTC'}
            </button>
          </div>

          <p className="alert-main-description mb-3">
            {alert?.alertMessageInternal}
          </p>

          {alert?.alertType?.name === alertTypeConstants.MISSED_WORK_START_TIME && (
            <div className="time-details-grid">
              <div className="time-detail-item">
                <span className="time-label">Working Day Start Time:</span>
                <span className="time-value">
                  {formatTime(alert?.metadata?.workingDayStartTimeOfTheMember)}
                </span>
              </div>
              <div className="time-detail-item">
                <span className="time-label">First Activity Time:</span>
                <span className="time-value">
                  {formatTime(alert?.metadata?.firstActivityTimeOfTheDay)}
                </span>
              </div>
              <div className="time-detail-item">
                <span className="time-label">Delay:</span>
                <span className="time-value">
                  {calculateDelay()}
                </span>
              </div>
            </div>
          )}

          {alert?.alertType?.name === alertTypeConstants.UNSCHEDULED_ABSENCE && (
            <div className="time-details-grid">
              <div className="time-detail-item">
                <span className="time-label">Absence From:</span>
                <span className="time-value">
                  {formatTime(alert?.metadata?.recentActivityTime)}
                </span>
              </div>
              <div className="time-detail-item">
                <span className="time-label">Absence To:</span>
                <span className="time-value">
                  {formatTime(alert?.metadata?.currentTime)}
                </span>
              </div>
            </div>
          )}

          {alert?.alertType?.name === alertTypeConstants.LOCATION_VIOLATION && (
            <div className="time-details-grid">
              <div className="time-detail-item">
                <span className="time-label">Allowed Work Location:</span>
                <span className="time-value">
                  {alert?.metadata?.allowedFormattedAddress}
                </span>
              </div>
              <div className="time-detail-item">
                <span className="time-label">Captured Location:</span>
                <span className="time-value">
                  {alert?.metadata?.formattedAddress}
                </span>
              </div>
              <div className="time-detail-item">
                <span className="time-label">Distance:</span>
                <span className="time-value">
                  {alert?.metadata?.distanceFromHome?.toFixed(2)}
                  {' '}
                  miles
                </span>
              </div>

              <GoogleMapWithDirections
                pointA={{ lat: alert.metadata.allowedLatitude, lng: alert.metadata.allowedLongitude }}
                pointB={{ lat: alert.metadata.latitude, lng: alert.metadata.longitude }}
              />
            </div>
          )}

          {alert?.alertType?.name === alertTypeConstants.UNRECOGNIZED_FACIAL_RECOGNITION && (
          <div className="time-details-grid">
            <div className="time-detail-item">
              <span className="time-label">Registered Face:</span>
              <span className="time-value">
                {alert?.metadata?.allowedFormattedAddress}
              </span>
            </div>
            <div className="time-detail-item">
              <span className="time-label">Captured Face:</span>
              <span className="time-value">
                {alert?.metadata?.formattedAddress}
              </span>
            </div>
          </div>
          )}

        </div>
      </div>
    </div>
  );
}

AlertDescription.propTypes = {
  alert: PropTypes.shape({
    alertMessageInternal: PropTypes.string.isRequired,
    alertType: PropTypes.shape({
      name: PropTypes.string,
    }),
    metadata: PropTypes.shape({
      firstActivityTimeOfTheDay: PropTypes.string,
      workingDayStartTimeOfTheMember: PropTypes.string,
      recentActivityTime: PropTypes.string,
      currentTime: PropTypes.string,
      allowedFormattedAddress: PropTypes.string,
      formattedAddress: PropTypes.string,
      distanceFromHome: PropTypes.number,
      allowedLatitude: PropTypes.number,
      allowedLongitude: PropTypes.number,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
  }),
};

export default AlertDescription;
