import React, {
  useState, useEffect, useContext,
} from 'react';
import {
  Box, Button, Menu, MenuItem,
} from '@mui/material';
import MultiSelect from '../../../components/muiCustomized/MultiSelect';
import { BootstrapInput } from '../../../components/muiCustomized/TextInput';
import EmailDeleteModal from './DeleteModal';
import EditModal from './EditModal';
import '../settings.css';
import emailImg from '../../../images/email.png';
import visibleImg from '../../../images/visible.svg';
import moreImg from '../../../images/more.svg';
import toggleOnImg from '../../../images/toggle-on.svg';
import toggleOffImg from '../../../images/toggle-off.svg';
import { sendRequest } from '../../../api/shootAPI';
import API from '../../../api/endpoints';
import { UserContext } from '../../../contexts/User';
import { NotificationContext } from '../../../contexts/Notification';

function ProductivityEmailSettings() {
  const {
    displayNotification,
  } = useContext(NotificationContext);
  const [emails, setEmails] = useState([]);
  const { activeWorkspace } = useContext(UserContext);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const isFilterOpen = Boolean(filterAnchorEl);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  const [teams, setTeams] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([{ id: 0, name: 'All' }]);
  const [selectedREmails, setSelectedREmails] = useState([{ id: 0, name: 'All' }]);
  const [selectedUsers, setSelectedUsers] = useState([{ id: 0, name: 'All' }]);
  const [filterEmails, setFilterEmails] = useState([{ id: 0, name: 'All' }]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showNewModal, setShowNewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const frequency = ['Daily', 'Weekly', 'Monthly'];

  const getAllTeams = async () => {
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.getAllTeamInfo(activeWorkspace.id),
      timeout: 60000,
    });

    if (response.success) {
      const mappedteams = response.data?.teams.map((team) => ({
        id: team.id,
        name: team.name,
      }));
      setTeams(mappedteams);
    }
  };

  const getAllMembers = async () => {
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.members(activeWorkspace.id),
    });
    if (response.success) {
      const mappedmembers = response.data?.members.map((member) => ({
        id: member.id,
        name: member.user?.fullName,
      }));
      setUsers(mappedmembers);
    }
  };

  const getAllEmails = async () => {
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.getProductivityEmailSettings(activeWorkspace.id),
      timeout: 60000,
    });

    if (response.success) {
      const { productivityEmailSettings } = response.data;

      const updatedEmails = [];
      productivityEmailSettings.forEach((setting) => {
        const newEmail = {
          id: updatedEmails.length + 1,
          emailId: setting.id,
          isEnabled: setting.isEnabled,
          emailSubject: setting.emailSubject,
          sendFrequency: setting.sendFrequency,
          teams: setting.teams,
          recipients: setting.recipients,
        };

        updatedEmails.push(newEmail);
      });

      setEmails(updatedEmails);
    } else {
      displayNotification({
        message: 'An error occured while getting emails',
        severity: 'error',
      });
    }
  };

  const createEmail = async (email) => {
    const response = await sendRequest({
      method: 'post',
      url: API.WORKSPACE.setProductivityEmailSettings(activeWorkspace.id),
      data: { email },
    });

    if (response.success) {
      displayNotification({
        message: 'Create email successfully',
        severity: 'success',
      });
      getAllEmails();
    } else {
      displayNotification({
        message: 'An error occured while creating email',
        severity: 'error',
      });
    }
  };

  const deleteEmail = async (email) => {
    const response = await sendRequest({
      method: 'delete',
      url: API.WORKSPACE.deleteProductivityEmailSettings(activeWorkspace.id),
      data: { email },
    });

    if (response.success) {
      getAllEmails();
    }
  };

  const updateEmail = async (email) => {
    const response = await sendRequest({
      method: 'put',
      url: API.WORKSPACE.updateProductivityEmailSettings(activeWorkspace.id),
      data: { email },
    });

    if (response.success) {
      getAllEmails();
    }
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleMenuClick = (event, id) => {
    setSelectedEmail(emails[id - 1]);
    setMenuAnchorEl(event.currentTarget);
  };

  const handleFilterEmailChange = (newValue) => {
    setSelectedEmails(newValue);
  };

  const handleFilterUsersChange = (newValue) => {
    setSelectedUsers(newValue);
  };

  const handleFilterREmailChange = (newValue) => {
    setSelectedREmails(newValue);
  };

  const handleDeleteClick = () => {
    setMenuAnchorEl(false);
    setShowDeleteModal(true);
  };

  const handleNewClick = () => {
    setShowNewModal(true);
  };

  const handleEditClick = (id) => {
    setSelectedEmail(emails[id - 1]);
    setShowEditModal(true);
  };

  const handleSearch = () => {
  };

  const handleReset = () => {
  };

  const handleImgClick = (emailId) => {
    emails.forEach((email) => {
      if (email.id === emailId) {
        const updatedEmail = {
          emailId: email.emailId,
          isEnabled: !email.isEnabled,
          emailSubject: email.emailSubject,
          sendFrequency: email.sendFrequency,
          teams: email.teams,
          recipients: email.recipients,
        };
        updateEmail(updatedEmail);
      }
    });
  };

  const handleSaved = (emailName, assignedTeams, assignedUsers, frequency) => {
    const combinedRecipients = [];
    const combinedTeams = [];
    assignedUsers.forEach((item) => {
      combinedRecipients.push(users[users.findIndex((u) => u.name === item.name)].id);
    });
    assignedTeams.forEach((item) => {
      combinedTeams.push(teams[teams.findIndex((t) => t.name === item.name)].id);
    });
    const email = {
      isEnabled: 1,
      emailSubject: emailName,
      sendFrequency: frequency,
      recipients: combinedRecipients.join(','),
      teams: combinedTeams.join(','),
    };
    createEmail(email);
  };

  const handleUpdated = (emailName, assignedTeams, assignedUsers, frequency) => {
    const combinedRecipients = [];
    const combinedTeams = [];
    assignedUsers.forEach((item) => {
      combinedRecipients.push(users[users.findIndex((u) => u.name === item.name)].id);
    });
    assignedTeams.forEach((item) => {
      combinedTeams.push(teams[teams.findIndex((t) => t.name === item.name)].id);
    });
    const email = {
      emailId: selectedEmail.emailId,
      isEnabled: 1,
      emailSubject: emailName,
      sendFrequency: frequency,
      recipients: combinedRecipients.join(','),
      teams: combinedTeams.join(','),
    };
    updateEmail(email);
  };

  useEffect(() => {
    getAllTeams();
    getAllMembers();
    getAllEmails();
  }, []);

  useEffect(() => {
    const mappedEmails = emails?.map((email) => ({
      id: email.id,
      name: email.emailSubject,
    }));
    setFilterEmails(mappedEmails);
  }, [emails]);

  return (
    <>
      {
            showDeleteModal && (
            <EmailDeleteModal
              closeModal={() => setShowDeleteModal(false)}
              email={selectedEmail.emailSubject}
              onDelete={() => {
                const email = {
                  emailId: selectedEmail.emailId,
                  isEnabled: selectedEmail.isEnabled,
                  emailSubject: selectedEmail.emailSubject,
                  sendFrequency: selectedEmail.sendFrequency,
                  teams: selectedEmail.teams,
                  recipients: selectedEmail.recipients,
                };
                deleteEmail(email);
              }}
            />
            )
        }
      {
            showNewModal && (
            <EditModal
              closeModal={() => setShowNewModal(false)}
              teams={teams}
              users={users}
              selectedEmail={{
                id: -1,
              }}
              onSaved={handleSaved}
            />
            )
        }
      {
            showEditModal && (
            <EditModal
              closeModal={() => setShowEditModal(false)}
              teams={teams}
              users={users}
              selectedEmail={selectedEmail}
              onSaved={handleUpdated}
            />
            )
        }
      <Box className="productivity-email-settings">
        <Box className="body-header">
          <Box className="title">
            Productivity Email Settings
          </Box>
          <Box className="sub-title">
            Manage your workspace&apos;s automated emails
          </Box>
        </Box>
        <Box className="body-main">
          <Box className="header">
            <Box className="group">
              <img className="image" src={emailImg} alt="" />
              <Box className="title">
                All Emails
              </Box>
            </Box>
            <Box className="group">
              <Button className="filter" onClick={handleFilterClick}>
                Filter & Search
              </Button>
              <Menu
                anchorEl={filterAnchorEl}
                open={isFilterOpen}
                onClose={handleFilterClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={{
                  '& .MuiMenu-list': {
                    padding: 0,
                  },
                }}
              >
                <div className="filter-panel p-3">
                  <div className="filter-label mb-1">Email Name</div>
                  <MultiSelect
                    options={[{ id: 0, name: 'All' }, ...filterEmails]}
                    value={selectedEmails}
                    handleChange={handleFilterEmailChange}
                  />
                  <div className="filter-label mb-1 mt-2">Recipient Name</div>
                  <MultiSelect
                    options={[{ id: 0, name: 'All' }, ...users]}
                    value={selectedUsers}
                    handleChange={handleFilterUsersChange}
                  />
                  <div className="filter-label mb-1 mt-2">Recipient Email</div>
                  <MultiSelect
                    options={[{ id: 0, name: 'All' }, ...users]}
                    value={selectedREmails}
                    handleChange={handleFilterREmailChange}
                  />
                  <div className="filter-label mb-1 mt-2">Keyword/Content</div>
                  <BootstrapInput
                    sx={{
                      '& .MuiInputBase-input': {
                        border: '1.5px solid',
                        borderColor: 'var(--sds-input-border)',
                      },
                    }}
                    fullWidth="100%"
                  />
                  <div className="d-flex justify-content-end gap-3 mt-2">
                    <button
                      type="button"
                      className="filter-reset-btn"
                      onClick={() => handleReset()}
                    >
                      Reset
                    </button>
                    <button
                      type="button"
                      className="filter-search-btn"
                      onClick={() => handleSearch()}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </Menu>
              <Button className="new" onClick={() => handleNewClick()}>
                + New Email
              </Button>
            </Box>
          </Box>
          <Box className="main">
            {emails && emails.map((email) => (
              <Box className="sub-option">
                <Box className="group">
                  {/* eslint-disable jsx-a11y/click-events-have-key-events */}
                  {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
                  <img
                    src={email.isEnabled ? toggleOnImg : toggleOffImg}
                    onClick={() => handleImgClick(email.id)}
                    alt=""
                  />
                  <Box className="texts">
                    <Box className="title">
                      {email.emailSubject}
                    </Box>
                    <Box className="sub-title">
                      {frequency[email.sendFrequency]}
                      {' '}
                      •
                      {' '}
                      {email.recipients.split(',').length}
                      {' '}
                      recipient
                      {email.recipients.split(',').length > 1 ? 's' : ''}
                      {' '}
                      •
                      {' '}
                      {email.teams.split(',').length}
                      {' '}
                      team
                      {email.teams.split(',').length > 1 ? 's' : ''}
                    </Box>
                  </Box>
                </Box>
                <Box className="group">
                  <Box className="preview">
                    <img className="image" src={visibleImg} alt="" />
                    <Box className="title">
                      Preview
                    </Box>
                  </Box>
                  <Button className="edit-btn" onClick={() => handleEditClick(email.id)}>
                    Edit
                  </Button>
                  {/* eslint-disable jsx-a11y/click-events-have-key-events */}
                  {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
                  <img className="image" src={moreImg} onClick={(e) => handleMenuClick(e, email.id)} alt="" />
                  <Menu
                    anchorEl={menuAnchorEl}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    sx={{
                      '& .MuiMenu-list': {
                        padding: 0,
                      },
                    }}
                  >
                    <Box className="menu">
                      <MenuItem
                        className="menu-item"
                        onClick={handleDeleteClick}
                      >
                        <Box className="text">
                          Delete Email
                        </Box>
                      </MenuItem>
                    </Box>
                  </Menu>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ProductivityEmailSettings;
