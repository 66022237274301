import React, {
  useState, useEffect, useContext, useCallback,
} from 'react';
import { Button, Box, Menu } from '@mui/material';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import { useLocation } from 'react-router';
import { UserContext } from '../../../contexts/User';
import { sendRequest } from '../../../api/shootAPI';
import API from '../../../api/endpoints';
import '../settings.css';
import CustomTable from '../../../components/table/CustomTable';
import useDebounce from '../../../hooks/useDebounce';
import DropDown from '../../../components/dropdown';
import EditModal from './EditModal';
// import DepartmentDeleteModal from './DeleteModal';
import Pagination from '../../../components/pagination';
import MultiSelect from '../../../components/muiCustomized/MultiSelect';
import { DESIGNATIONS } from '../../../constants/designation';

const TABLE_HEADERS = [
  {
    label: 'Employee Name',
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'name',
    colSpan: 1.5,
  },
  {
    label: 'Designation',
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'designation',
    colSpan: 1.5,
  },
  {
    label: 'Teams Assigned',
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'teamsAssigned',
    colSpan: 1.5,
  },
  {
    label: (
      <div>
        Screenshot
        <p style={{ fontSize: 10, lineHeight: '10px' }}>(10 mins period)</p>
      </div>
    ),
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'screenshot',
    colSpan: 1.5,
  },
  {
    label: 'Webcam Audit',
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'webcamAudit',
    colSpan: 1.5,
  },
  {
    label: 'PTO Policy',
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'ptoPolicy',
  },
  {
    label: 'Location',
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'location',
    colSpan: 1.5,
  },
  {
    label: 'Action',
    sortable: false,
  },
];

const CustomTableStyles = {
  container: {
    marginTop: 1,
  },
  header: {
    backgroundColor: 'var(--dark-10)',
    fontSize: '14px',
    '.MuiTableSortLabel-iconDirectionAsc': {
      transform: 'rotate(0deg) !important',
    },
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff !important',
    },
    '&:hover': {
      backgroundColor: 'var(--dark-10) !important',
    },
  },
  cell: {
    button: {
      textWrap: 'nowrap',
      color: 'var(--dark-2)',
      borderColor: 'var(--dark-4) !important',
    },
  },
};

function TeamManagement() {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const page = query.get('page');
  const { activeWorkspace } = useContext(UserContext);
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  // const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [totalCounts, setTotalCounts] = useState(0);
  const [currentPage, setCurrentPage] = useState(page ? parseInt(page, 10) : 1);
  const [totalPages, setTotalPages] = useState(1);
  const [teams, setTeams] = useState([]);
  const [ptoPolicies, setPtoPolicies] = useState([]);

  const [filterMembers, setFilterMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([{ id: 0, name: 'All' }]);
  const [selectedDesignations, setSelectedDesignations] = useState([{ id: 0, name: 'All' }]);
  const [selectedTeams, setSelectedTeams] = useState([{ id: 0, name: 'All' }]);
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([{ id: 0, name: 'All' }]);

  const [onlyUnassigned, setOnlyUnassigned] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { debounce } = useDebounce();

  const getMembers = useCallback(async (params) => {
    if (activeWorkspace) {
      setIsLoading(true);
      const response = await sendRequest({
        method: 'get',
        url: API.WORKSPACE.teamManagementMembers(activeWorkspace.id),
        params,
      });

      if (response.success) {
        // if (response.data?.members?.length) {
        if (params?.pageSize) {
          const members = response.data?.members.map((member) => ({
            name: member.user.fullName,
            designation: member.designation,
            teamsAssigned: member.teamMates?.length ? (
              <div className="d-flex gap-1">
                {member.teamMates.map((teamMate) => (
                  <div className="team-assigned" key={teamMate.team.id}>
                    {teamMate.team.name}
                  </div>
                ))}
              </div>
            ) : (
              <div className="unassigned">Unassigned</div>
            ),
            screenshot: `${member.screenshotsPerPeriod}x${member.blurScreenshots ? ', Blur' : ''}`,
            webcamAudit: member.webcamAuditsPerPeriod,
            ptoPolicy: member.offTimePolicies.length ? member.offTimePolicies[0].name : 'None',
            location: member.user.userLocation.country,
            actions: <DropDown
              title="Actions"
              options={[
                {
                  text: 'Edit Employee',
                  fontColor: '#061232',
                  handleClick: () => {
                    setSelectedMember(member);
                    setShowEditModal(true);
                  },
                },
                {
                  text: 'View Activity',
                  fontColor: '#061232',
                  handleClick: () => {
                    // setSelectedMember(member);
                    // setShowDeleteConfirmModal(true);
                  },
                },
              ]}
            />,
          }));
          setMembers(members);
        } else {
          const mappedMembers = response.data?.members.map((member) => ({
            id: member.id,
            name: member.user?.fullName,
          }));
          setFilterMembers([{ id: 0, name: 'All' }, ...mappedMembers]);
          const locationsArray = response.data?.members.map((member) => (member.user.userLocation.country));
          const uniqueLocations = [...new Set(locationsArray)];
          const mappedLocations = uniqueLocations.map((location) => (
            {
              id: location,
              name: location,
            }
          ));
          setLocations(mappedLocations);
        }
        // }
        setTotalCounts(response.data?.pageInfo?.totalCounts);
        setTotalPages(response.data?.pageInfo?.totalPages);
        setIsLoading(false);
      }
    }
  }, [activeWorkspace]);

  const handleSort = debounce((sortKey, sortOrder) => getMembers({
    sortKey,
    sortOrder,
  }), 250);

  const handleSearch = async () => {
    const memberIds = selectedMembers.map((item) => item.id);
    const designations = selectedDesignations.map((item) => item.id);
    const teams = selectedTeams.map((item) => item.id);
    const locations = selectedLocations.map((item) => item.id);

    await getMembers({
      page: currentPage,
      pageSize,
      memberIds: memberIds[0] === 0 ? null : memberIds,
      designations: designations[0] === 0 ? null : designations,
      teams: teams[0] === 0 ? null : teams,
      locations: locations[0] === 0 ? null : locations,
      onlyUnassigned,
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (pageSize) => {
    setCurrentPage(1);
    setTotalPages(Math.ceil(totalCounts / pageSize));
    setPageSize(pageSize);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterMemberChange = (newValue) => {
    setSelectedMembers(newValue);
  };

  const handleFilterDesignationChange = (newValue) => {
    setSelectedDesignations(newValue);
  };

  const handleFilterTeamChange = (newValue) => {
    setSelectedTeams(newValue);
  };

  const handleFilterLocationChange = (newValue) => {
    setSelectedLocations(newValue);
  };

  const handleReset = () => {
    setSelectedMembers([{ id: 0, name: 'All' }]);
    setSelectedDesignations([{ id: 0, name: 'All' }]);
    setSelectedTeams([{ id: 0, name: 'All' }]);
    setSelectedLocations([{ id: 0, name: 'All' }]);
  };

  const handleUnassigned = async () => {
    setCurrentPage(1);
    if (onlyUnassigned) {
      await getMembers({
        page: 1,
        pageSize,
        onlyUnassigned: false,
      });
    } else {
      await getMembers({
        page: 1,
        pageSize,
        onlyUnassigned: true,
      });
    }
    setOnlyUnassigned(!onlyUnassigned);
  };

  useEffect(() => {
    getMembers({});
  }, []);

  useEffect(() => {
    handleSearch();
  }, [pageSize, currentPage]);

  const getTeams = async () => {
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.getAllTeamInfo(activeWorkspace.id),
      timeout: 60000,
    });

    if (response.success) {
      setTeams(response.data.teams);
    }
  };

  const getPtoPolicies = async () => {
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.offTimePolicies(activeWorkspace.id),
      timeout: 60000,
    });

    if (response.success) {
      setPtoPolicies(response.data.ptoPolicies.filter((policy) => policy.isArchived === false));
    }
  };

  useEffect(() => {
    getTeams();
    getPtoPolicies();
  }, []);

  return (
    <>
      {
        showEditModal && (
        <EditModal
          member={selectedMember}
          closeModal={() => setShowEditModal(false)}
          onMemberSaved={() => {
            getMembers({
              page: currentPage,
              pageSize,
            });
          }}
          workspaceId={activeWorkspace.id}
          teams={teams}
          ptoPolicies={ptoPolicies}
        />
        )
      }
      <Box className="px-3 py-2 d-flex justify-content-between align-items-center">
        <Box>
          <Box className="size-s text-600 lh-base fc-dark">
            Team Audit & Filtering
          </Box>
          <Box className="size-xs text-400 lh-sm fc-dark">
            Manage employees&apos; team assignments, roles, and permissions.
          </Box>
        </Box>
        <Box className="d-flex align-items-center gap-2">
          <Button
            variant="outlined"
            onClick={handleUnassigned}
            sx={{ color: '#CC4525', borderColor: '#E2E2E2' }}
          >
            {onlyUnassigned ? 'All Members' : 'Unassigned Members' }
          </Button>
          <Button
            variant="outlined"
            onClick={handleFilterClick}
            sx={{ color: '#4469A1', borderColor: '#E2E2E2' }}
          >
            Filter & Search
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleFilterClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              '& .MuiMenu-list': {
                padding: 0,
              },
            }}
          >
            <div className="filter-panel p-3">
              <div className="filter-label mb-1">Employee Name</div>
              <MultiSelect
                options={filterMembers}
                value={selectedMembers}
                handleChange={handleFilterMemberChange}
              />
              <div className="filter-label mb-1 mt-2">Designation</div>
              <MultiSelect
                options={[{ id: 0, name: 'All' }, ...DESIGNATIONS]}
                value={selectedDesignations}
                handleChange={handleFilterDesignationChange}
              />
              <div className="filter-label mb-1 mt-2">Teams Assigned</div>
              <MultiSelect
                options={[{ id: 0, name: 'All' }, ...teams]}
                value={selectedTeams}
                handleChange={handleFilterTeamChange}
              />
              <div className="filter-label mb-1 mt-2">Location</div>
              <MultiSelect
                options={[{ id: 0, name: 'All' }, ...locations]}
                value={selectedLocations}
                handleChange={handleFilterLocationChange}
              />
              <div className="d-flex justify-content-end gap-3 mt-2">
                <button
                  type="button"
                  className="filter-reset-btn"
                  onClick={() => handleReset()}
                >
                  Reset
                </button>
                <button type="button" className="filter-search-btn" onClick={() => handleSearch()}>Search</button>
              </div>
            </div>
          </Menu>
        </Box>
      </Box>
      <CustomTable
        headers={TABLE_HEADERS}
        items={members}
        loading={isLoading}
        handleSort={handleSort}
        styles={CustomTableStyles}
      />
      <Pagination
        currentPage={currentPage}
        pageSize={pageSize}
        totalPages={totalPages}
        totalCounts={totalCounts}
        onPageChange={handlePageChange}
        setPageSize={handlePageSizeChange}
        styles={{ margin: 0 }}
      />
    </>
  );
}

export default TeamManagement;
