import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Switch } from '@mui/material';
// import Toggle from '../../components/Toggle';

function ViewActivityTable({
  tableData,
  date,
  handleSelectDate,
  collapseTable,
  setCollapseTable,
  showCaptures,
  setShowCaptures,
  activityType,
  workspaceMember,
}) {
  const { firstName, lastName } = workspaceMember.user;

  return (
    <>
      <table className="table activity-table" style={{ marginTop: 24 }}>
        <thead>
          <tr className={`${!collapseTable && 'uncollapsed'} table-row`}>
            <th scope="col" className="table-cell">
              <div className="avatar-user">
                {firstName?.slice(0, 1)}
                {lastName?.slice(0, 1)}
              </div>
              <div className="name-user">
                {`${firstName} ${lastName}`}
              </div>
            </th>
            {tableData.dates?.map((day, index) => (
              <th
                key={index}
                scope="col"
                style={{ cursor: 'pointer' }}
                className={moment(date).day() === index ? 'selected-date table-cell' : 'table-cell'}
                onClick={() => handleSelectDate(new Date(day), activityType)}
              >
                <div className="dayname-date">{moment(day).date()}</div>
                <div className="subdate-container">
                  <div className="day-date">{moment(day).format('ddd')}</div>
                  <div className="month-date">{moment(day).format('MMM')}</div>
                </div>
              </th>
            ))}
            <th scope="col" className="table-cell">
              Total
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className={`${!collapseTable && 'uncollapsed'} table-row`}>
            <th scope="row" className="table-cell table-header">Time</th>
            {tableData.times.map((time, index) => (
              <td
                key={index}
                role="gridcell"
                style={{ cursor: 'pointer' }}
                className={
                  `table-cell table-value ${moment(date).day() === index && 'selected-date'}`
                }
                onClick={() => handleSelectDate(new Date(tableData.dates[index]), activityType)}
              >
                <span className="table-value">{time}</span>
              </td>
            ))}
            <td className="table-cell table-value">
              {tableData.totalTime}
            </td>
          </tr>
          <tr className={`${!collapseTable && 'uncollapsed'} table-row`}>
            <th scope="row" className="table-cell table-header">Avg Activity</th>
            {tableData.avgActivities.map((avgActivity, index) => (
              <td
                key={index}
                role="gridcell"
                style={{ cursor: 'pointer' }}
                className={
                  `table-cell table-value ${moment(date).day() === index && 'selected-date'}`
                }
                onClick={() => handleSelectDate(new Date(tableData.dates[index]), activityType)}
              >
                {avgActivity
                  ? (
                    <span className={`
                      ${avgActivity <= 25 && 'low-activity'}
                      ${avgActivity >= 75 && ' high-activity'}`}
                    >
                      {`${avgActivity}%`}
                    </span>
                  )
                  : '--%'}
              </td>
            ))}
            <td className="table-cell table-value">
              {tableData.totalAvgActivity
                ? (
                  <span className={`
                    ${tableData.totalAvgActivity <= 25 && 'low-activity'}
                    ${tableData.totalAvgActivity >= 75 && ' high-activity'}`}
                  >
                    {`${tableData.totalAvgActivity}%`}
                  </span>
                )
                : '--%'}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="buttons-container">
        <div data-testid="switch">
          <Switch checked={showCaptures} onChange={() => setShowCaptures(!showCaptures)} color="secondary" />
          <span className="switch-label">Show Webcam & Screenshots</span>
        </div>
        {showCaptures && (
        <>
          {/* <div className="toggle-button" data-testid="toggle-button"> */}
          {/*  <Toggle */}
          {/*    activeLabel={activityType} */}
          {/*    options={[ */}
          {/*      { */}
          {/*        label: 'Screenshots', */}
          {/*        onClick: () => { */}
          {/*          handleSelectDate(date, 'Screenshots'); */}
          {/*        }, */}
          {/*      }, */}
          {/*      { */}
          {/*        label: 'Webcam Audit', */}
          {/*        onClick: () => { */}
          {/*          handleSelectDate(date, 'Webcam Audit'); */}
          {/*        }, */}
          {/*      }, */}
          {/*      { */}
          {/*        label: 'Webcam Audit', */}
          {/*        onClick: () => { */}
          {/*          handleSelectDate(date, 'Webcam Audit'); */}
          {/*        }, */}
          {/*      }, */}
          {/*      { */}
          {/*        label: 'Webcam Audit', */}
          {/*        onClick: () => { */}
          {/*          handleSelectDate(date, 'Webcam Audit'); */}
          {/*        }, */}
          {/*      }, */}
          {/*    ]} */}
          {/*  /> */}
          {/* </div> */}
          <button
            type="button"
            className="collapse-button"
            data-testid="collapse-button"
            onClick={() => setCollapseTable(!collapseTable)}
          >
            {collapseTable ? (
              <div className="flex-col">
                <div style={{ display: 'flex', justifyContent: 'center', height: 15 }}>
                  <div className="fa fa-caret-down" />
                </div>
                <div style={{ height: 6, borderTop: '2px solid #546077' }} />
              </div>
            ) : (
              <div className="flex-col">
                <div style={{ height: 6, borderBottom: '2px solid #546077' }} />
                <div style={{ display: 'flex', justifyContent: 'center', height: 15 }}>
                  <div className="fa fa-caret-up" />
                </div>
              </div>
            )}
          </button>
        </>
        )}
      </div>
    </>
  );
}

ViewActivityTable.propTypes = {
  showCaptures: PropTypes.bool.isRequired,
  setShowCaptures: PropTypes.func.isRequired,
  setCollapseTable: PropTypes.func.isRequired,
  tableData: PropTypes.shape({
    dates: PropTypes.arrayOf(PropTypes.string),
    times: PropTypes.arrayOf(PropTypes.string),
    avgActivities: PropTypes.arrayOf(PropTypes.number),
    totalTime: PropTypes.string,
    totalAvgActivity: PropTypes.number,
  }).isRequired,
  collapseTable: PropTypes.bool,
  // setCollapseTable: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date),
  handleSelectDate: PropTypes.func.isRequired,
  activityType: PropTypes.string.isRequired,
  workspaceMember: PropTypes.shape({
    id: PropTypes.number.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ViewActivityTable;
