const DESIGNATIONS = [
  {
    id: 'Others',
    name: 'Others',
  },
  {
    id: 'Software Developer',
    name: 'Software Developer',
  },
  {
    id: 'Senior Data Scientist',
    name: 'Senior Data Scientist',
  },
  {
    id: 'Team Lead',
    name: 'Team Lead',
  },
  {
    id: 'Business Process Analyst',
    name: 'Business Process Analyst',
  },
  {
    id: 'BI Analyst',
    name: 'BI Analyst',
  },
  {
    id: 'Customer Success Associate',
    name: 'Customer Success Associate',
  },
  {
    id: 'Software Developer II',
    name: 'Software Developer II',
  },
  {
    id: 'QA Lead',
    name: 'QA Lead',
  },
  {
    id: 'Business Analyst',
    name: 'Business Analyst',
  },
  {
    id: 'Junior Software Developer',
    name: 'Junior Software Developer',
  },
  {
    id: 'Senior Business Analyst',
    name: 'Senior Business Analyst',
  },
  {
    id: 'QA Manager',
    name: 'QA Manager',
  },
  {
    id: 'HR',
    name: 'HR',
  },
];

module.exports = {
  DESIGNATIONS,
};
