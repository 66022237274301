import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import NavigationLayout from '../../components/NavigationLayout';
import TeamManagementTab from './teamManagement';
import GlobalTeamSettingsTab from './globalTeamSettings';
import TeamVisibilityControlsTab from './teamVisibilityControls';
import ProductivityEmailSettingsTab from './productivityEmailSettings';

const StyledCard = styled(Card)`
    border-radius: 8px;
    background: var(--Dark-Dark-11-White, #FFF);
    box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 3px 5px 0px rgba(9, 30, 66, 0.20);
    padding-top: 8px;
    min-height: calc(100vh - 200px);
`;

const StyledTab = styled(Tab)`
    text-transform: capitalize;
    color: var(--Dark-Dark-03, #546077);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    &.Mui-selected {
        color: var(--Dark-Dark-01, #0E1E40);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
    }
`;

function CustomTabPanel(props) {
  const {
    children, value, index, p = 4,
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
      <Box sx={{ p }}>
        {children}
      </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  p: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([null]),
  ]),
};

function TeamSettings() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <NavigationLayout title="Team Management">
        <div style={{ marginTop: 24 }}>
          <StyledCard>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="settings tab"
                sx={{
                  '& .MuiTabs-indicator': {
                    backgroundColor: 'var(--Blue-Blue-01, #213D69)',
                  },
                }}
              >
                <StyledTab label="Team Management" id="tab_team_management" />
                <StyledTab label="Global Team Settings" id="tab_global_team_settings" />
                <StyledTab label="Team Visibility Controls" id="tab_team_visibility" />
                <StyledTab label="Productivity Email Settings" id="tab_productivity_email_settings" />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0} p={0}>
              <TeamManagementTab />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1} p={0}>
              <GlobalTeamSettingsTab />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2} p={0}>
              <TeamVisibilityControlsTab />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3} p={0}>
              <ProductivityEmailSettingsTab />
            </CustomTabPanel>
          </StyledCard>
        </div>
      </NavigationLayout>
    </div>
  );
}

TeamSettings.propTypes = {

};

export default TeamSettings;
