import React, {
  useState, Fragment, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import moment from 'moment-timezone';

const StyledTr = styled('tr')`
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.60) 100%), #D8E6FC;
`;

const StyledTd = styled('td')`
    border-bottom: 1px solid #A7C5F2;
`;

function UrlActivity({ data, hourBlock, additionalProperty }) {
  const [expanded, setExpanded] = useState(null);
  const [expandedId, setExpandedId] = useState(null);

  const toggleExpand = (website, id) => {
    setExpanded(expanded === website && id === expandedId ? null : website);
    setExpandedId(id);
  };

  const additionalData = useMemo(() => data[additionalProperty], [data, additionalProperty]);

  return (
    <Fragment key={`${additionalProperty}_${hourBlock}`}>
      <div className="rounded-xl border shadow mb-3">
        <table className="w-100 text-sm text-left">
          <thead className="border-b">
            <tr>
              <th className="py-2 px-3" colSpan={3}>
                {moment().set({ hours: hourBlock, minutes: 0 }).format('h:mm A')}
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(additionalData).map((index) => (
              <Fragment key={index}>
                <tr
                  className="cursor-pointer hover:bg-gray-50"
                  onClick={() => additionalData[index].urls && toggleExpand(index, index)}
                >
                  <td className="py-2 px-3 w-25">
                    {additionalData[index].urls ? (
                      <span className="mr-2 pointer-event">
                        {expanded === index && expandedId === data.id ? '−' : '+'}
                      </span>
                    ) : (
                      <span className="mr-2">•</span>
                    )}
                    {index}
                  </td>
                  <td>
                    {' '}
                    {' '}
                    {' '}
                  </td>
                  <td className="w-25">
                    {moment.duration(additionalData[index].totalTimeSpent, 'seconds').humanize()}
                  </td>
                </tr>

                {additionalData[index].urls && expanded === index && expandedId === index && (
                <>
                  {/* <StyledTr> */}
                  {/*  <StyledTd className="py-2 px-5 w-25">Website</StyledTd> */}
                  {/*  <StyledTd className="py-2 w-50">URL</StyledTd> */}
                  {/*  <StyledTd className="py-2 w-25">Time Spent</StyledTd> */}
                  {/* </StyledTr> */}
                  {additionalData[index].urls.map((d, idx) => (
                    <StyledTr key={idx}>
                      <StyledTd className="py-2 px-5 w-25">{index}</StyledTd>
                      <StyledTd className="py-2 w-50">{d.url}</StyledTd>
                      <StyledTd className="py-2 w-25">{d.timeSpent}</StyledTd>
                    </StyledTr>
                  ))}
                </>
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
}

UrlActivity.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    website: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    details: PropTypes.arrayOf(PropTypes.shape({
      url: PropTypes.string.isRequired,
      time: PropTypes.string.isRequired,
    })),
  })).isRequired,
  hourBlock: PropTypes.string.isRequired,
  additionalProperty: PropTypes.string.isRequired,
};

export default UrlActivity;
