import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  Button, Chip, Menu,
} from '@mui/material';
import { UnfoldMore } from '@mui/icons-material/';
import CustomTable from '../../components/table/CustomTable';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';
import MemberNameEmailColumn from '../../components/table/MemberNameEmailColumn';
import MultiSelect from '../../components/muiCustomized/MultiSelect';
import ViewProfileRequestPopup from './viewProfileRequestPopup';

const TABLE_HEADERS = [
  {
    label: 'Member',
    colSpan: 1,
  },
  {
    label: 'Profile Field',
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'name',
    colSpan: 0.5,
  },
  {
    label: 'Manager',
    colSpan: 0.5,
  },
  {
    label: 'Status',
    colSpan: 0.5,
  },
  {
    label: 'Action',
    colSpan: 0.5,
  },
];

const CustomTableStyles = {
  container: {
    marginTop: 1,
  },
  header: {
    backgroundColor: 'var(--dark-10)',
    fontSize: '14px',
    '.MuiTableSortLabel-iconDirectionAsc': {
      transform: 'rotate(0deg) !important',
    },
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff !important',
    },
    '&:hover': {
      backgroundColor: 'var(--dark-10) !important',
    },
  },
  cell: {
    button: {
      textWrap: 'nowrap',
      color: 'var(--dark-2)',
      borderColor: 'var(--dark-4) !important',
      '&:disabled': {
        color: 'var(--dark-5)',
        borderColor: 'var(--dark-7) !important',
        cursor: 'not-allowed',
        pointerEvents: 'all !important',
      },
    },
  },
};

const Status = [
  { id: 0, name: 'All' },
  { id: 2, name: 'Pending' },
  { id: 1, name: 'Approved' },
  { id: 3, name: 'Rejected' },
];

function ProfileUpdateRequests() {
  const { search } = useLocation();
  const { activeWorkspace } = useContext(UserContext);
  const query = new URLSearchParams(search);
  const page = query.get('page');
  const history = useHistory();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedProfileRequest, setSelectedProfileRequest] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [profileUpdateRequests, setProfileUpdateRequests] = useState([]);
  const [currentPage, setCurrentPage] = useState(page ? parseInt(page, 10) : 1);
  const [selectedStatus, setSelectedStatus] = useState([{ id: 0, name: 'All' }]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const onClickView = (profileRequest) => {
    setSelectedProfileRequest(profileRequest);
    setIsOpenModal(true);
  };

  const handleClose = () => {
    setIsOpenModal(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    history.push(`?page=${page}`);
  };

  const getProfileUpdateRequests = async (params) => {
    setIsLoading(true);
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.getProfileUpdateRequests(activeWorkspace.id),
      params: {
        pageSize: 10,
        ...params,
      },
    });
    if (response.success) {
      const mappedRequests = response.data?.map((req) => ({
        memberName: <MemberNameEmailColumn
          firstName={req?.member?.user?.firstName}
          lastName={req?.member?.user?.lastName}
          email={req?.member?.user?.email}
          component="div"
        />,
        field: req.profile_field,
        manager: req.member.Manager.user.firstName,
        status: (
          <Chip
            size="small"
            label={req.status.charAt(0).toUpperCase() + req.status.slice(1)}
            color={
                     // eslint-disable-next-line no-nested-ternary
                    req.status === 'approved' ? 'success' : req.status === 'rejected' ? 'error' : 'warning'
                  }
          />
        ),
        actions: (
          <Button
            type="button"
            className="inactive-status"
            onClick={() => onClickView(req)}
            variant="outlined"
          >
            View Request
          </Button>
        ),
      }));
      setProfileUpdateRequests(mappedRequests);
      setIsLoading(false);
      setTotalPages(response.data?.totalPages ?? 1);
    } else {
      // handle API errors
    }
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterStatusChange = (newValue) => {
    setSelectedStatus(newValue);
  };

  const handleReset = () => {
    setSelectedStatus([{ id: 0, name: 'All' }]);
  };

  const handleSearch = () => {
    const status = selectedStatus.map((item) => item.name);
    const params = {
      status: status[0] === 'All' ? null : status,
      page: currentPage,
    };
    getProfileUpdateRequests(params);
  };

  const handleApproval = async (status) => {
    setIsUpdating(true);
    const response = await sendRequest({
      method: 'put',
      data: { status },
      url: API.WORKSPACE.profileUpdateApprovalByManager(selectedProfileRequest?.id),
    });
    setIsUpdating(false);
    if (response.success) {
      setIsOpenModal(false);
      getProfileUpdateRequests({ page: currentPage });
    }
  };

  useEffect(() => {
    getProfileUpdateRequests({ page: currentPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <>
      <div className="py-2 px-3 d-flex justify-content-between align-items-center">
        <div>
          <h4 className="size-s text-600 lh-base fc-dark mb-1">
            All Profile Update Requests
          </h4>
          <p className="size-xs text-400 lh-sm fc-dark">
            Verify and approve profile update requests from your team members
          </p>
        </div>
        <button className="filter-btn" type="button" onClick={handleFilterClick}>Filter & Search</button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleFilterClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiMenu-list': {
              padding: 0,
            },
          }}
        >
          <div className="filter-panel p-3">
            <div className="filter-label mb-1 mt-2">Status</div>
            <MultiSelect options={Status} value={selectedStatus} handleChange={handleFilterStatusChange} />
            <div className="d-flex justify-content-end gap-3 mt-2">
              <button
                type="button"
                className="filter-reset-btn"
                onClick={() => handleReset()}
              >
                Reset
              </button>
              <button type="button" className="filter-search-btn" onClick={() => handleSearch()}>Search</button>
            </div>
          </div>
        </Menu>
      </div>
      <div>
        <CustomTable
          headers={TABLE_HEADERS}
          items={profileUpdateRequests}
          loading={isLoading}
          pages={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          styles={CustomTableStyles}
        />
      </div>
      {isOpenModal && (
      <ViewProfileRequestPopup
        selectedRequest={selectedProfileRequest}
        isOpen={isOpenModal}
        handleClose={handleClose}
        handleApproval={handleApproval}
        isUpdating={isUpdating}
      />
      )}
    </>
  );
}

export default ProfileUpdateRequests;
