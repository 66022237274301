import React, {
  useState, useEffect, useContext,
} from 'react';
import {
  Box, Button, Checkbox, Radio,
} from '@mui/material';
import { Description } from '../../../components/styledComponents';
import '../settings.css';
import { sendRequest } from '../../../api/shootAPI';
import API from '../../../api/endpoints';
import { UserContext } from '../../../contexts/User';
import { NotificationContext } from '../../../contexts/Notification';

function GlobalTeamSettings() {
  const { activeWorkspace } = useContext(UserContext);
  const {
    displayNotification,
  } = useContext(NotificationContext);
  const [policies, setPolicies] = useState([]);
  const [policyFlags, setPolicyFlags] = useState([]);
  const [webcamAudit, setWebcamAudit] = useState(0);
  const [webcamAuditFrequency, setWebcamAuditFrequency] = useState(0);
  const [screenshot, setScreenshot] = useState(0);
  const [blurScreenshot, setBlurScreenshot] = useState(0);
  const [screenshotFrequency, setScreenshotFrequency] = useState(0);
  const frequency = ['None', 'Daily', 'Hourly', 'Weekly'];

  const reset = () => {
    policyFlags.fill(0);
    setWebcamAudit(0);
    setScreenshot(0);
    setBlurScreenshot(0);
  };

  const getTeamSettings = async () => {
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.getGlobalSettings(activeWorkspace.id),
      timeout: 60000,
    });

    if (response.success) {
      const setting = response.data.teamSettings;

      setting.policyIds.split(',').forEach((policyId) => {
        policies.forEach((policy, index) => {
          if (policy.id === parseInt(policyId, 10)) {
            policyFlags[index] = 1;
          }
        });
      });
      setWebcamAudit(setting.enabledWebcamAudit);
      frequency.forEach((period, index) => {
        if (period === setting.webcamAuditFrequency) {
          setWebcamAuditFrequency(index);
        }
      });
      setScreenshot(setting.enabledScreenShot);
      setBlurScreenshot(setting.enabledBlurScreenShot);
      setScreenshotFrequency(setting.screenShotFrequency);
    } else {
      displayNotification({
        message: 'An error occurs while getting Global Team Setting',
        severity: 'error',
      });
    }
  };

  const setTeamSettings = async (setting) => {
    const response = await sendRequest({
      method: 'put',
      url: API.WORKSPACE.updateGlobalSettings(activeWorkspace.id),
      data: { setting },
    });
    if (response.success) {
      displayNotification({
        message: 'Global Team Setting has been saved successfully.',
        severity: 'success',
      });
      getTeamSettings();
    } else {
      displayNotification({
        message: 'An error occurs while saving Global Team Setting',
        severity: 'error',
      });
    }
  };

  const save = () => {
    const array = [];
    if (policyFlags) {
      policyFlags.forEach((flag, index) => {
        if (flag) array.push(policies[index].id);
      });
    }
    const setting = {
      policyIds: array.join(','),
      enabledWebcamAudit: webcamAudit,
      webcamAuditFrequency: frequency[webcamAuditFrequency],
      enabledScreenShot: screenshot,
      enabledBlurScreenShot: blurScreenshot,
      screenShotFrequency: screenshotFrequency,
    };

    setTeamSettings(setting);
  };

  const getPtoPolicies = async () => {
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.offTimePolicies(activeWorkspace.id),
      timeout: 60000,
    });

    if (response.success) {
      setPolicies(response.data.ptoPolicies);
      setPolicyFlags(new Array(response.data.ptoPolicies.length).fill(0));
    }
  };

  useEffect(() => {
    if (policies.length) getTeamSettings();
  }, [policies]);

  const handleCheckboxChange = (id, isChecked) => {
    setPolicyFlags((prevFlags) => {
      const updatedFlags = [...prevFlags];

      updatedFlags[id] = isChecked;

      return updatedFlags;
    });
  };

  useEffect(() => {
    getPtoPolicies();
  }, []);

  return (
    <Box className="global-team-setting">
      <Box className="body-header">
        <Box className="title">
          Global Team Settings
        </Box>
        <Box className="sub-title">
          Set organization-wide policies for all teams.
        </Box>
      </Box>
      <Box className="body-main">
        <Box className="sub-option">
          <Box className="title">
            Time-Off Policies
          </Box>
          {policies && policies.map((policy, index) => (
            <Box className="option1" key={policy.id}>
              <Checkbox
                className="check-box"
                onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                checked={policyFlags[index]}
              />
              <Description className="descript">
                {policy.name}
              </Description>
            </Box>
          ))}
        </Box>
        <Box className="sub-option">
          <Box className="title">
            Webcam Audit
          </Box>
          <Box className="main">
            <Box className="option1">
              <Checkbox
                className="check-box"
                onChange={() => {
                  setWebcamAudit(!webcamAudit);
                  setWebcamAuditFrequency(1);
                }}
                checked={webcamAudit}
              />
              <Description className="descript">
                Enable Webcam Audit
              </Description>
            </Box>
            <Box className="option1">
              <Checkbox className="check-box hidden" />
              <Description className="contrast">
                webcam audit frequency Option
              </Description>
            </Box>
            <Box className="option2">
              <Radio
                className="radio"
                name="auditFrequency"
                onChange={() => setWebcamAuditFrequency(0)}
                value="None"
                checked={!webcamAudit || webcamAuditFrequency === 0}
              />
              <Description className="descript">
                None
              </Description>
              <Radio
                className="radio"
                name="auditFrequency"
                onChange={() => setWebcamAuditFrequency(1)}
                value="Daily"
                checked={webcamAudit && webcamAuditFrequency === 1}
              />
              <Description className="descript">
                Daily
              </Description>
              <Radio
                className="radio"
                name="auditFrequency"
                onChange={() => setWebcamAuditFrequency(2)}
                value="Hourly"
                checked={webcamAudit && webcamAuditFrequency === 2}
              />
              <Description className="descript">
                Hourly
              </Description>
              <Radio
                className="radio"
                name="auditFrequency"
                onChange={() => setWebcamAuditFrequency(3)}
                value="Weekly"
                checked={webcamAudit && webcamAuditFrequency === 3}
              />
              <Description className="descript">
                Weekly
              </Description>
            </Box>
          </Box>
        </Box>
        <Box className="sub-option">
          <Box className="title">
            Screenshot Frequency
          </Box>
          <Box className="option1">
            <Checkbox
              className="check-box"
              onChange={() => {
                setScreenshot(!screenshot);
                setScreenshotFrequency(1);
              }}
              checked={screenshot}
            />
            <Description className="descript">
              Enable Screenshot
            </Description>
          </Box>
          <Box className="option1">
            <Checkbox
              className="check-box"
              onChange={() => setBlurScreenshot(!blurScreenshot)}
              checked={blurScreenshot}
            />
            <Description className="descript">
              Automatically Blur Screenshots
            </Description>
          </Box>
          <Box className="option1">
            <Checkbox className="check-box hidden" />
            <Description className="contrast">
              Screenshot frequency Options
            </Description>
          </Box>
          <Box className="option2">
            <Radio
              className="radio"
              name="screenFrequency"
              onChange={() => setScreenshotFrequency(0)}
              value="None"
              checked={!screenshot || screenshotFrequency === 0}
            />
            <Description className="descript">
              None
            </Description>
            <Radio
              className="radio"
              name="screenFrequency"
              onChange={() => setScreenshotFrequency(1)}
              value="First"
              checked={screenshot && screenshotFrequency === 1}
            />
            <Description className="descript">
              1x
            </Description>
            <Radio
              className="radio"
              name="screenFrequency"
              onChange={() => setScreenshotFrequency(2)}
              value="Double"
              checked={screenshot && screenshotFrequency === 2}
            />
            <Description className="descript">
              2x
            </Description>
            <Radio
              className="radio"
              name="screenFrequency"
              onChange={() => setScreenshotFrequency(3)}
              value="Triple"
              checked={screenshot && screenshotFrequency === 3}
            />
            <Description className="descript">
              3x
            </Description>
          </Box>
        </Box>
        <Box className="sub-option">
          <Box className="d-flex align-items-center">
            <Button className="reset-button" onClick={() => { reset(); }}>
              Reset to Defaults
            </Button>
            <Button className="save-button" onClick={() => { save(); }}>
              Save Changes
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default GlobalTeamSettings;
