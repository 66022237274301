import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  GoogleMap, Marker, Polyline,
} from '@react-google-maps/api';

const mapContainerStyle = {
  width: '100%',
  height: '400px',
};

const polylineOptions = {
  strokeColor: '#FF0000',
  strokeOpacity: 1.0,
  strokeWeight: 2,
};

function GoogleMapWithDirections({ pointA, pointB }) {
  const mapRef = useRef(null);
  const [mapCenter, setMapCenter] = React.useState({ lat: 0, lng: 0 });
  const [mapZoom, setMapZoom] = React.useState(2);

  // Handle map bounds and zoom level
  const updateBounds = () => {
    if (!mapRef.current || (!pointA && !pointB)) return;

    const bounds = new window.google.maps.LatLngBounds();

    if (pointA) bounds.extend(pointA);
    if (pointB) bounds.extend(pointB);

    // If we have at least one valid point
    if (!bounds.isEmpty()) {
      mapRef.current.fitBounds(bounds);

      // Add padding to prevent markers from touching map edges
      const padding = 50;
      mapRef.current.fitBounds(bounds, padding);

      // Set center state for initial render
      setMapCenter(bounds.getCenter());

      // Adjust zoom level for single point
      if (!pointA || !pointB) {
        setMapZoom(15);
      }
    }
  };

  useEffect(() => {
    updateBounds();
  }, [pointA, pointB]);

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={mapCenter}
      zoom={mapZoom}
      onLoad={(map) => {
        mapRef.current = map;
        updateBounds();
      }}
    >
      {/* Markers */}
      {pointA && (
        <Marker
          position={pointA}
          title="Allowed Work Location"
          icon="http://maps.google.com/mapfiles/ms/icons/green-dot.png"
        />
      )}
      {pointB && (
        <Marker
          position={pointB}
          title="Captured Location"
          icon="http://maps.google.com/mapfiles/ms/icons/red-dot.png"
        />
      )}

      {/* Polyline to show distance */}
      {pointA && pointB && <Polyline path={[pointA, pointB]} options={polylineOptions} />}
    </GoogleMap>
  );
}

GoogleMapWithDirections.propTypes = {
  pointA: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }),
  pointB: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }),
};

GoogleMapWithDirections.defaultProps = {
  pointA: null,
  pointB: null,
};

export default GoogleMapWithDirections;
