import React, { Fragment, useMemo } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

// const data = [
//   {
//     application: 'Krajcik-Weber',
//     session: '30',
//     time: '01:05:32',
//   },
//   {
//     application: 'Price and Sons',
//     session: '02',
//     time: '00:45:32',
//   },
//   {
//     application: 'Lesch PLC',
//     session: '46',
//     time: '00:35:32',
//   },
// ];

function AppActivity({ data, hourBlock, additionalProperty }) {
  // const [expanded, setExpanded] = useState(null);

  // const toggleExpand = (application) => {
  //   setExpanded(expanded === application ? null : application);
  // };

  const additionalData = useMemo(() => data[additionalProperty], [data, additionalProperty]);

  console.log('additionalData', additionalData);

  return (
    <div className="rounded-xl border shadow mb-3">
      <table className="w-100 text-sm text-left">
        <thead className="border-b">
          <tr>
            <th className="py-2 px-3" colSpan={3}>
              {moment().set({ hours: hourBlock, minutes: 0 }).format('h:mm A')}
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(additionalData).map((key) => (
            <Fragment key={key}>
              <tr
                className="cursor-pointer hover:bg-gray-50"
                // onClick={() => item.details && toggleExpand(item.application)}
              >
                <td className="py-2 px-3 w-50">
                  {key}
                </td>
                <td className="w-25">{moment.duration(additionalData[key].time, 'seconds').humanize()}</td>
                <td className="w-25">{additionalData[key].sessions}</td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}

AppActivity.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    application: PropTypes.string.isRequired,
    session: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
  })).isRequired,
  hourBlock: PropTypes.string.isRequired,
  additionalProperty: PropTypes.string.isRequired,
};

export default AppActivity;
