import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Popover, Box, Button, Select, MenuItem, InputLabel,
} from '@mui/material';
import '../settings.css';

function AddModal({
  viewerType,
  viewers,
  onSaved,
}) {
  const [modalAnchorEl, setModalAnchorEl] = useState(null);
  const openModal = Boolean(modalAnchorEl);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [access, setAccess] = useState(0);

  const handleViewerChanged = (event) => {
    const selectedValue = event.target.value;
    const index = viewers.findIndex((viewer) => viewer.name === selectedValue);

    setSelectedIndex(index + 1);
  };

  const handleAccessChanged = (event) => {
    const selectedValue = event.target.value;
    setAccess(selectedValue === 'Full Access' ? 1 : 2);
  };

  return (
    <div className="add-modal">
      <Button
        className={openModal ? 'button selected' : 'button'}
        onClick={(e) => setModalAnchorEl(e.currentTarget)}
      >
        Add
        {' '}
        {viewerType === 2 ? 'Member' : 'Team'}
      </Button>
      <Popover
        open={openModal}
        anchorEl={modalAnchorEl}
        onClose={() => setModalAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box className="add-modal-container">
          <Box className="header">
            Add New&nbsp;
            {viewerType === 2 ? 'Member' : 'Team'}
          </Box>
          <Box className="main">
            <Box className="sub-option">
              <Box className="title">
                {viewerType === 2 ? 'Member' : 'Team'}
              </Box>
              <Select
                className="select"
                renderValue={(selected) => {
                  if (!selected) {
                    return (
                      <InputLabel
                        sx={{
                          fontSize: '16px',
                          color: '#212529',
                          lineHeight: '20px',
                          opacity: '40%',
                          marginBottom: '0px',
                        }}
                      >
                        Required
                      </InputLabel>
                    );
                  }
                  return (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      { selected }
                    </Box>
                  );
                }}
                onChange={handleViewerChanged}
                displayEmpty
              >
                {viewers && viewers.map((viewer) => (
                  <MenuItem className="menu-item" value={viewer.name}>
                    {viewer.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box className="sub-option">
              <Box className="title">
                Access Level
              </Box>
              <Select
                className="select"
                renderValue={(selected) => {
                  if (!selected) {
                    return (
                      <InputLabel
                        sx={{
                          fontSize: '16px',
                          color: '#212529',
                          lineHeight: '20px',
                          opacity: '40%',
                          marginBottom: '0px',
                        }}
                      >
                        Required
                      </InputLabel>
                    );
                  }
                  return (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      { selected }
                    </Box>
                  );
                }}
                onChange={handleAccessChanged}
                displayEmpty
              >
                <MenuItem className="menu-item" value="Full Access">
                  Full Access
                </MenuItem>
                <MenuItem className="menu-item" value="Limited Access">
                  Limited Access
                </MenuItem>
              </Select>
            </Box>
            <Button
              className="button"
              onClick={() => {
                if (!selectedIndex || !access) return;
                onSaved(viewers[selectedIndex - 1].id, viewerType, access - 1);
                setModalAnchorEl(null);
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Popover>
    </div>
  );
}

AddModal.propTypes = {
  viewerType: PropTypes.number.isRequired,
  viewers: PropTypes.arrayOf({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  onSaved: PropTypes.func.isRequired,
};

export default AddModal;
