import React, { useContext, useEffect, useState } from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import _ from 'lodash';
import moment from 'moment-timezone';
import { TransformWrapper, TransformComponent } from '@kokarn/react-zoom-pan-pinch';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Avatar from '@mui/material/Avatar';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';
import './organizationChart.css';
import { convertSecToTimeFormat, currentMonthWeekRange, getDatesInRange } from '../../utils/time';
import DateRangePicker from '../../components/dateRangePicker';
import { Navbar, Sidebar } from '../../components';
import { ClockIcon, IdleIcon, TimerClockIcon } from '../../components/Icon';
import SelectInput from '../../components/muiCustomized/CustomMenuSelect';
import SubordinatesModal from './Subordinates';
import { dummyUserImage } from '../../utils/system';
import { sendRequest } from '../../api/shootAPI';
import alertImg from '../../images/alert.svg';
import noticeImg from '../../images/notification.svg';

/* eslint-disable react/forbid-prop-types */

const StyledCard = styled(Card)(() => ({
  background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.95) 100%), #4062E5',
  display: 'inline-block',
  width: 165,
  borderRadius: 16,
  overflow: 'visible',
  position: 'relative',
}));

// const ExpandButton = styled(IconButton)(({ theme, expanded }) => ({
//   transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
//   position: 'absolute',
//   bottom: -20,
//   left: '50%',
//   marginLeft: -12,
//   transition: theme.transitions.create('transform', {
//     duration: theme.transitions.duration.short,
//   }),
//   backgroundColor: '#ffffff',
//   boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
//   '&:hover': {
//     backgroundColor: '#f5f5f5',
//   },
//   padding: 4,
// }));

const StyledAvatar = styled(Avatar)(() => ({
  backgroundColor: '#ECECF4',
}));

const CardContentStyled = styled(CardContent)({
  padding: '16px 16px 12px 16px !important',
});

function Organization({ org, handleShowSubordinates, handleShowDetails }) {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'member',
    drop: () => ({ name: org.name }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = canDrop && isOver;
  // eslint-disable-next-line no-nested-ternary
  const backgroundColor = isActive ? '#ddffd2' : canDrop ? '#ffeedc' : 'blue';

  return (
    <StyledCard variant="outlined" ref={drop} style={{ backgroundColor }} onClick={() => handleShowDetails(org)}>
      <CardContentStyled className="p-2 pt-4">
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div onClick={() => handleShowSubordinates(org)} className="org-card-header">
          <img
            /* eslint-disable-next-line max-len */
            src={org.profilePicture || dummyUserImage}
            alt="User"
            className="mb-2 member-image"
          />
        </div>
        <div className="org-name">
          {org.name}
        </div>
        <div className="org-role mb-1">
          {org.role}
        </div>
        <div className="member-card-info mb-1">
          <div className="card-info-text">
            <ClockIcon width="12" height="12" color="#213D69" className="mr-1" />
            Hours Worked:
          </div>
          <div className="card-info-time" data-type="worked">
            {convertSecToTimeFormat(org.hoursWorked, 'HH:MM')}
          </div>
        </div>
        <div className="member-card-info mb-1">
          <div className="card-info-text">
            <IdleIcon width="12" height="12" color="#213D69" className="mr-1" />
            Idle Time:
          </div>
          <div className="card-info-time" data-type="idle">
            {convertSecToTimeFormat(org.idleTime, 'HH:MM')}
          </div>
        </div>
        <div className="member-card-info mb-1">
          <div className="card-info-text">
            <TimerClockIcon width="12" height="12" color="#213D69" className="mr-1" />
            Expected Hours:
          </div>
          <div className="card-info-time" data-type="expected">
            {convertSecToTimeFormat(org.expectedHours, 'HH:MM')}
          </div>
        </div>
      </CardContentStyled>
    </StyledCard>
  );
}

function Account({ a }) {
  const [{ isDragging }, drag] = useDrag({
    item: { name: a.name, type: 'member' },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        console.log(`You dropped ${item.name} into ${dropResult.name}!`);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;

  return (
    <StyledCard variant="outlined" ref={drag} style={{ cursor: 'pointer', opacity }}>
      <CardHeader
        avatar={(
          <StyledAvatar>
            <AccountBalanceIcon color="secondary" />
          </StyledAvatar>
            )}
        title={a.name}
      />
    </StyledCard>
  );
}

function Product({ p }) {
  return (
    <StyledCard variant="outlined">
      <CardContent>
        <Typography variant="subtitle2">{p.name}</Typography>
      </CardContent>
    </StyledCard>
  );
}

function Node({
  o, parent, handleShowSubordinates, handleShowDetails,
}) {
  const [collapsed, setCollapsed] = React.useState(o.collapsed ?? false);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  React.useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    o.collapsed = collapsed;
  }, [collapsed]);

  const T = parent
    ? TreeNode
    : (props) => (
      <Tree
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...props}
        lineWidth="2px"
        lineColor="#bbc"
        lineBorderRadius="12px"
      >
        {/* eslint-disable-next-line react/prop-types */}
        {props.children}
      </Tree>
    );

  return collapsed ? (
    <T
      label={(
        <Organization
          org={o}
          onCollapse={handleCollapse}
          collapsed={collapsed}
          handleShowSubordinates={handleShowSubordinates}
          handleShowDetails={handleShowDetails}
        />
          )}
    />
  ) : (
    <T
      label={(
        <Organization
          org={o}
          onCollapse={handleCollapse}
          collapsed={collapsed}
          handleShowSubordinates={handleShowSubordinates}
          handleShowDetails={handleShowDetails}
        />
          )}
    >
      {_.map(o.member, (a) => (
        <TreeNode label={<Account a={a} />} key={a.name}>
          <TreeNode label={<Product p={a.product} />} />
        </TreeNode>
      ))}
      {_.map(o.children, (c) => (
        <Node
          handleShowSubordinates={handleShowSubordinates}
          handleShowDetails={handleShowDetails}
          o={c}
          parent={o}
          key={c.name}
        />
      ))}
    </T>
  );
}

function OrganizationChart() {
  const { activeWorkspace } = useContext(UserContext);
  const [organization, setOrganization] = React.useState({});
  const [date, setDate] = React.useState(currentMonthWeekRange());
  const [managers, setManagers] = React.useState([]);
  const [manager, setManager] = React.useState(null);
  const [showSubordinates, setShowSubordinates] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [subordinates, setSubordinates] = useState([]);
  const [details, setDetails] = useState([]);
  const [expectedHours, setExpectedHours] = useState(0);
  const [teams, setTeams] = useState([]);
  const [teammates, setTeammates] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [alertTypes, setAlertTypes] = useState([]);

  const buildHierarchy = (members, parentId, dayDifference, managers = new Set()) => {
    // Find direct subordinates
    const directSubordinates = members.filter((m) => m.reportingManager === parentId);

    // Identify reporting managers (children who have their own subordinates)
    const children = directSubordinates.filter((m) => {
      const isManager = members.some((sub) => sub.reportingManager === m.id);
      if (isManager) {
        managers.add({
          id: m.id,
          value: m.id,
          text: `${m.user?.firstName || 'Unknown'} ${m.user?.lastName || ''}`,
        });
      }
      return isManager;
    });

    let totalSeconds = 0;
    let activeSeconds = 0;
    let expectedHours = 0;

    // Recursively build the hierarchy for each child
    const subOrg = children.map((child) => {
      const childHierarchy = buildHierarchy(members, child.id, dayDifference, managers);

      // Merge child totals into parent totals
      totalSeconds += childHierarchy.hierarchy.totalSeconds;
      activeSeconds += childHierarchy.hierarchy.activeSeconds;
      expectedHours += childHierarchy.hierarchy.expectedHours;

      return childHierarchy.hierarchy;
    });

    // Collect all subordinates recursively
    const allSubordinates = directSubordinates.flatMap((sub) => {
      const childHierarchy = buildHierarchy(members, sub.id, dayDifference, managers);
      return [sub, ...childHierarchy.hierarchy.subordinates];
    });

    // Fetch member's own activity data
    const member = members.find((m) => m.id === parentId) || {};
    const memberTotalSeconds = member.dailyActivity ? +member.dailyActivity.totalSeconds || 0 : 0;
    const memberActiveSeconds = member.dailyActivity ? +member.dailyActivity.activeSeconds || 0 : 0;
    const memberExpectedHours = 3600 * 8 * dayDifference;

    // Add own totals to the calculated totals
    totalSeconds += memberTotalSeconds;
    activeSeconds += memberActiveSeconds;
    expectedHours += memberExpectedHours;

    let manager;
    managers.forEach((m) => {
      if (m.id === member.reportingManager) manager = m.text;
    });

    return {
      hierarchy: {
        id: parentId,
        name: `${member.user?.firstName || 'Unknown'} ${member.user?.lastName || ''}`,
        role: member.accountType || 'Unknown',
        department: member.department,
        hoursWorked: totalSeconds,
        idleTime: totalSeconds - activeSeconds,
        expectedHours,
        totalSeconds,
        activeSeconds,
        collapsed: false,
        profilePicture: member.user?.profilePicture || null,
        children: subOrg, // Only direct reporting managers
        subordinates: allSubordinates, // All subordinates, direct and indirect
        workEmail: member.user?.email,
        manager,
        startDate: member.firstActivityTime,
        country: member.user?.userLocation.country,
      },
      managers: Array.from(managers), // Convert Set to Array
    };
  };

  const getOrgChartData = async (memberId = null) => {
    const [startDate, endDate] = date;
    const dates = getDatesInRange(startDate, endDate);
    const startOfDay = moment(dates[0]).utc().startOf('day')
      .format('YYYY-MM-DD HH:mm:ss');
    const endOfDay = moment(dates.at(-1)).utc().endOf('day')
      .format('YYYY-MM-DD HH:mm:ss');
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.organizationChart(activeWorkspace.id),
      params: {
        startOfDay,
        endOfDay,
        memberId,
      },
    });

    const { members, rootMember } = response.data;

    const calculateWeekdays = (start, end) => {
      let weekdays = 0;
      const current = moment(start).startOf('day'); // Start from the beginning of the day
      const endMoment = moment(end).startOf('day'); // End at the beginning of the day

      while (current.isSameOrBefore(endMoment)) {
        const day = current.isoWeekday(); // 1 = Monday, 7 = Sunday
        if (day !== 6 && day !== 7) {
          // eslint-disable-next-line no-plusplus
          weekdays++;
        }
        current.add(1, 'day');
      }

      return weekdays;
    };

    const dayDifference = calculateWeekdays(startOfDay, endOfDay);
    const { hierarchy, managers } = buildHierarchy(members, rootMember.id, dayDifference);
    setOrganization(hierarchy);
    setExpectedHours(dayDifference * 8 * 3600);
    if (!memberId) setManagers(managers);
  };

  useEffect(() => {
    getOrgChartData();
  }, [date]);

  const handleManagerChange = (e) => {
    setManager(e.target.value);
    getOrgChartData(e.target.value);
  };

  // const handleWheel = (e) => {
  //   e.stopPropagation();
  // };

  const handleShowSubordinates = (org) => {
    setShowSubordinates(true);
    setSubordinates(org);
  };

  const getAlerts = async (memberId) => {
    const [startDate, endDate] = date;
    const dates = getDatesInRange(startDate, endDate);
    const startOfDay = moment(dates[0]).utc().startOf('day')
      .format('YYYY-MM-DD HH:mm:ss');
    const endOfDay = moment(dates.at(-1)).utc().endOf('day')
      .format('YYYY-MM-DD HH:mm:ss');
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.getAlertOfMember(activeWorkspace.id),
      params: {
        startOfDay,
        endOfDay,
        memberId,
      },
    });

    if (response.success) {
      setAlerts(response.data.alerts);
    }
  };

  const handleShowDetails = (org) => {
    setShowDetails(true);
    setDetails(org);
    getAlerts(org.id);
  };

  const getTeams = async () => {
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.getAllTeamInfo(activeWorkspace.id),
      timeout: 60000,
    });

    if (response.success) {
      setTeams(response.data.teams);
    }
  };

  const getTeammates = async () => {
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.getAllTeamMates(),
      timeout: 60000,
    });

    if (response.success) {
      setTeammates(response.data.teammates);
    }
  };

  const getAlertTypes = async () => {
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.getAlertTypes(),
      timeout: 60000,
    });

    if (response.success) {
      setAlertTypes(response.data.alertTypes);
    }
  };

  useEffect(() => {
    getTeams();
    getTeammates();
    getAlertTypes();
  }, []);

  const getCurrentTeam = (id) => {
    let team = '';
    teammates.forEach((mate) => {
      if (mate.memberId === id) {
        teams.forEach((t) => {
          if (t.id === mate.teamId) team += `${t.name}, `;
        });
      }
    });
    return team.substring(0, team.length - 2);
  };

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Navbar />
      <div style={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
        <Sidebar />
        {showSubordinates
            && (
            <SubordinatesModal
              closeModal={() => setShowSubordinates(false)}
              subordinates={subordinates}
              expectedHours={expectedHours}
            />
            )}

        <div style={{
          flex: 1,
          marginLeft: '240px',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          height: '100%',
        }}
        >
          {/* Header */}
          <div
            className="org-page-navbar"
            style={{
              backgroundColor: 'white',
              padding: '16px',
              flexShrink: 0,
              marginTop: '60px',
            }}
          >
            <div className="org-page-caption">
              Organization Chart
            </div>
            <div className="flex-row gap-2">
              <SelectInput
                id="manager"
                menuItems={managers}
                menuWidth="202px"
                placeholder="Select Manager"
                value={manager}
                handleForm={handleManagerChange}
              />
              <DateRangePicker
                value={date}
                onChange={(_date) => setDate(_date)}
                placement="bottomEnd"
              />
            </div>
          </div>

          {/* Chart Container */}
          <div style={{
            display: 'flex',
            flexDirection: 'row',
          }}
          >
            <div style={{
              flex: 1,
              position: 'relative',
              backgroundColor: '#ECECF4',
              overflow: 'hidden',
            }}
            >
              <TransformWrapper
                initialScale={0.75}
                minScale={0.5}
                maxScale={2}
                wheel={{ disabled: true }}
                pinch={{ disabled: true }}
                doubleClick={{ disabled: false }}
                limitToBounds={false}
                centerOnInit
              >
                {({ zoomIn, zoomOut, resetTransform }) => (
                  <>
                    {/* Zoom Controls */}
                    <div style={{
                      position: 'absolute',
                      top: '16px',
                      right: '16px',
                      display: 'flex',
                      gap: '8px',
                      background: 'white',
                      padding: '8px',
                      borderRadius: '5px',
                      boxShadow: '0px 2px 10px rgba(0,0,0,0.2)',
                      zIndex: 100,
                    }}
                    >
                      <button type="button" onClick={() => zoomIn()}>➕</button>
                      <button type="button" onClick={() => zoomOut()}>➖</button>
                      <button type="button" onClick={() => resetTransform()}>🔄</button>
                    </div>

                    {/* Chart Content */}
                    <TransformComponent
                      wrapperStyle={{
                        width: '100%',
                        height: '100%',
                      }}
                      contentStyle={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '32px',
                      }}
                    >
                      <DndProvider backend={HTML5Backend}>
                        <Node
                          o={organization}
                          handleShowSubordinates={handleShowSubordinates}
                          handleShowDetails={handleShowDetails}
                        />
                      </DndProvider>
                    </TransformComponent>
                  </>
                )}
              </TransformWrapper>
            </div>
            {
              showDetails && (
                <>
                  <div className="sidebar">
                    <div className="details">
                      <div className="title">
                        <div className="group">
                          <img className="img" src={details.profilePicture || dummyUserImage} alt="" />
                          <div className="sub-group">
                            <div className="name">{details.name}</div>
                            <div className="role">{details.role}</div>
                          </div>
                        </div>
                      </div>
                      <div className="buttons">
                        <Link className="link" to="/members/alerts">
                          <Button className="button" variant="outlined">View Alerts</Button>
                        </Link>
                        <Link className="link" to="/members/team-engagement">
                          <Button className="button" variant="outlined">View Engagement</Button>
                        </Link>
                      </div>
                      <hr className="divbar" />
                      <div className="user">
                        <div className="option">
                          <div className="title">
                            Name:
                          </div>
                          <div className="value">
                            {details.name}
                          </div>
                        </div>
                        <div className="option">
                          <div className="title">
                            Designation:
                          </div>
                          <div className="value">
                            {details.role}
                          </div>
                        </div>
                        <div className="option">
                          <div className="title">
                            Department:
                          </div>
                          <div className="value">
                            {details.department}
                          </div>
                        </div>
                        <div className="option">
                          <div className="title">
                            Team:
                          </div>
                          <div className="value">
                            {getCurrentTeam(details.id)}
                          </div>
                        </div>
                        <div className="option">
                          <div className="title">
                            Manager:
                          </div>
                          <div className="value">
                            {details.manager}
                          </div>
                        </div>
                        <div className="option">
                          <div className="title">
                            Country:
                          </div>
                          <div className="value">
                            {details.country}
                          </div>
                        </div>
                        <div className="option">
                          <div className="title">
                            Start Date:
                          </div>
                          <div className="value">
                            {details.startDate}
                          </div>
                        </div>
                        <div className="option">
                          <div className="title">
                            Work Phone:
                          </div>
                          <div className="value" />
                        </div>
                        <div className="option">
                          <div className="title">
                            Work Email:
                          </div>
                          <div className="value">
                            {details.workEmail}
                          </div>
                        </div>
                      </div>
                      <hr className="divbar" />
                      <div className="time">
                        <div className="option">
                          <div className="title">
                            Hours Worked Today:
                          </div>
                          <div className="value">
                            {convertSecToTimeFormat(details.hoursWorked, 'HH:MM')}
                          </div>
                        </div>
                        <div className="option">
                          <div className="title">
                            Quality Hours Today:
                          </div>
                          <div className="value">
                            {convertSecToTimeFormat(details.hoursWorked, 'HH:MM')}
                          </div>
                        </div>
                        <div className="option">
                          <div className="title">
                            Idle Time Today:
                          </div>
                          <div className="value">
                            {convertSecToTimeFormat(details.idleTime, 'HH:MM')}
                          </div>
                        </div>
                        <div className="option">
                          <div className="title">
                            Expected Hours Today:
                          </div>
                          <div className="value">
                            {convertSecToTimeFormat(details.expectedHours, 'HH:MM')}
                          </div>
                        </div>
                      </div>
                      <hr className="divbar" />
                      {alerts && alerts.map((alert, index) => (
                        <div className="description" key={index}>
                          <div className="option">
                            <div className="wrapper alert">
                              <img src={alertImg} className="img" alt="" />
                            </div>
                            <div className="group">
                              <div className="title">
                                Description/ Alert Triggered
                              </div>
                              <div className="text">
                                {alertTypes[alert.alertTypeId - 1].triggerCondition}
                              </div>
                            </div>
                          </div>
                          <div className="option">
                            <div className="wrapper action">
                              <img src={noticeImg} className="img" alt="" />
                            </div>
                            <div className="group">
                              <div className="title">
                                {alert.status === 'Pending' ? 'Action Taken' : 'Automated Action'}
                              </div>
                              <div className="text">
                                {alert.status === 'Pending' ? 'Acknowledged Alert' : 'Requested Acknowledgement'}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <button className="closeBtn" onClick={() => setShowDetails(false)} type="button">×</button>
                </>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}

Organization.propTypes = {
  org: PropTypes.shape({
    name: PropTypes.string,
    role: PropTypes.string,
    hoursWorked: PropTypes.number,
    profilePicture: PropTypes.string,
    expectedHours: PropTypes.number,
    idleTime: PropTypes.number,
    children: PropTypes.array,
    member: PropTypes.array,
    subordinates: PropTypes.array,
  }).isRequired,
  handleShowSubordinates: PropTypes.func.isRequired,
  handleShowDetails: PropTypes.func.isRequired,
};

Account.propTypes = {
  a: PropTypes.shape({
    name: PropTypes.string.isRequired,
    product: PropTypes.object,
  }).isRequired,
};

Product.propTypes = {
  p: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

Node.propTypes = {
  o: PropTypes.shape({
    collapsed: PropTypes.bool,
    name: PropTypes.string,
    member: PropTypes.array,
    children: PropTypes.array,
  }).isRequired,
  parent: PropTypes.object,
  handleShowSubordinates: PropTypes.func.isRequired,
  handleShowDetails: PropTypes.func.isRequired,
};

OrganizationChart.propTypes = {
  organization: PropTypes.shape({
    name: PropTypes.string,
    children: PropTypes.array,
    member: PropTypes.array,
  }),
};

export default OrganizationChart;
