import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, Button, Box, Grid, InputLabel,
} from '@mui/material';
import _ from 'lodash';
import { styled } from '@mui/material/styles';
import { CalendarIcon } from '../../components/Icon';
import { formatDate } from '../../utils/time';
import MemberNameEmailColumn from '../../components/table/MemberNameEmailColumn';
import API from '../../api/endpoints';
import { sendRequest } from '../../api/shootAPI';
import { TextInput } from '../../components/muiCustomized/TextInput';

const CustomBox = styled(Box)`
  width: 168px;
  height: 168px;
  border-radius: 8px;
`;

function ViewProfileRequestPopup({
  selectedRequest, isOpen, isUpdating, handleClose, handleApproval,
}) {
  const [faceIdDataPath, setFaceIdDataPath] = useState('');
  const [location, setLocation] = useState(null);
  const [profileUpdateRequests, setProfileUpdateRequests] = useState(null);

  const fetchFaceIdData = async () => {
    const { success, data } = await sendRequest({
      method: 'get',
      url: API.USER.fetchProfileRequest(selectedRequest.member.id),
    });
    if (success) {
      if (data.faceIdData) {
        setFaceIdDataPath(data.faceIdData.image_path);
      }
      if (data.location) {
        setLocation(data.location);
      }
      if (data.profileUpdateRequests) {
        const keyedProfileUpdateRequests = _.keyBy(data.profileUpdateRequests, 'profile_field');
        setProfileUpdateRequests(keyedProfileUpdateRequests);
      }
    }
  };

  useEffect(() => {
    fetchFaceIdData();
  }, [selectedRequest.profile_field]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="sm"
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '600px',
          margin: 'auto',
        },
      }}
      fullWidth
    >
      <DialogContent>
        <div className="request-modal-container">
          <div className="d-flex align-c gap-2 mb-4">
            <span className="request-status-badge" data-status={selectedRequest?.status}>
              {`Profile Update Request - ${selectedRequest?.status}`}
            </span>
            <span className="request-date-badge">
              <CalendarIcon width="20" height="20" />
              {selectedRequest?.createdAt ? formatDate(selectedRequest?.createdAt) : ''}
            </span>
          </div>

          <div className="d-flex align-c gap-3 mb-3">
            <div className="request-avatar">
              <MemberNameEmailColumn
                firstName={selectedRequest?.member?.user?.firstName}
                lastName={selectedRequest?.member?.user?.lastName}
                profilePicture={selectedRequest?.member?.user?.profilePicture}
                email=""
                component="div"
              />
            </div>
            <div className="request-user-info">
              <h5 className="request-user-name">{selectedRequest?.member?.user?.fullName}</h5>
              <div className="request-user-role">{selectedRequest?.member?.user?.role}</div>
            </div>
          </div>

          <div>
            <Grid item xs={12}>
              {selectedRequest.profile_field === 'address' && (
                <>
                  <Grid item xs={12} sx={{ position: 'relative' }}>
                    <TextInput
                      label="Current Address"
                      value={location?.description || ''}
                      disabled
                    />
                  </Grid>

                  {profileUpdateRequests?.address?.new_value?.formattedAddress && (
                  <Grid item xs={12}>
                    <InputLabel
                      sx={{
                        fontSize: '18px',
                        color: '#212529',
                        margin: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                      shrink
                      htmlFor="new-requested-address"
                    >
                      New Requested Address
                    </InputLabel>
                    <TextInput
                      id="new-requested-address"
                      value={profileUpdateRequests.address.new_value.formattedAddress}
                      disabled
                    />
                  </Grid>
                  )}
                </>
              )}

              {selectedRequest.profile_field === 'face_id' && (
              <Grid container spacing={2}>
                {/* First Face ID Block */}
                <Grid item xs={6}>
                  <InputLabel
                    sx={{
                      fontSize: '18px', color: '#212529', margin: 0, position: 'relative',
                    }}
                    shrink
                    htmlFor="face-id-1"
                  >
                    Current Face ID
                  </InputLabel>

                  {faceIdDataPath && (
                    <CustomBox className="avatar">
                      <img src={faceIdDataPath} width="100%" height="100%" alt="avatar" />
                    </CustomBox>
                  )}
                </Grid>

                {/* Requested Face ID Block */}
                {profileUpdateRequests?.face_id?.new_value && (
                <Grid item xs={6}>
                  <InputLabel
                    sx={{ fontSize: '18px', color: '#212529', margin: 0 }}
                    shrink
                    htmlFor="face-id-2"
                  >
                    Requested Face ID
                  </InputLabel>
                  <CustomBox className="avatar">
                    <img
                      src={profileUpdateRequests.face_id.new_value.image_path}
                      width="100%"
                      height="100%"
                      alt="avatar"
                    />
                  </CustomBox>
                </Grid>
                )}
              </Grid>
              )}
            </Grid>
          </div>

          {selectedRequest?.status === 'pending' && (
          <div className="d-flex justify-content-end pt-4">
            <Button
              variant="contained"
              color="success"
              disabled={isUpdating}
              onClick={() => handleApproval('approved')}
              sx={{
                fontSize: '14px',
                textTransform: 'capitalize',
                marginRight: '8px',
              }}
            >
              {isUpdating ? 'Approving...' : 'Approve'}
            </Button>
            <Button
              variant="contained"
              color="error"
              disabled={isUpdating}
              onClick={() => handleApproval('rejected')}
              sx={{
                fontSize: '14px',
                textTransform: 'capitalize',
              }}
            >
              {isUpdating ? 'Rejecting...' : 'Reject'}
            </Button>
          </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

ViewProfileRequestPopup.propTypes = {
  selectedRequest: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    profile_field: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    new_value: PropTypes.object,
    createdAt: PropTypes.string,
    member: PropTypes.shape({
      id: PropTypes.number,
      user: PropTypes.shape({
        profilePicture: PropTypes.string,
        fullName: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        role: PropTypes.string,
      }),
    }),
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleApproval: PropTypes.func.isRequired,
};

export default ViewProfileRequestPopup;
