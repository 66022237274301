import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, Button } from '@mui/material';
import { BootstrapInput } from '../../../components/muiCustomized/TextInput';
import TrashIcon from '../../../images/trash.png';
import VisibleImg from '../../../images/visible.svg';
import InvisibleImg from '../../../images/invisible.svg';
import { Description } from '../../../components/styledComponents';

function EmailDeleteModal({
  closeModal,
  email,
  onDelete,
}) {
  const [showPassword, setshowPassword] = useState(0);

  const handleClickView = () => {
    setshowPassword(!showPassword);
  };

  return (
    <Modal open onClose={closeModal} className="flex-row align-c jstfy-c delete-email-modal">
      <Box className="delete-modal">
        <img src={TrashIcon} width="48" height="48" alt="trash" />
        <div className="modal-body">
          <Description
            fontSize={20}
            fontWeight={600}
            color="#0E1E40"
            marginTop={16}
          >
            Are you sure you want to delete this email?
          </Description>
          <Description
            fontSize={14}
            fontWeight={400}
            color="#293855"
            marginTop={8}
          >
            You’re about to delete the “
            <span style={{ fontWeight: 600 }}>{email}</span>
            ” email from
            {' '}
            <b>XYZ Corporation LLC</b>
            {' '}
            and this action cannot be undone. Please enter the password to proceed.
          </Description>
          <Box className="input-container">
            <BootstrapInput
              className="input-field"
              sx={{
                '& .MuiInputBase-input': {
                  border: '1.5px solid',
                  borderColor: 'var(--sds-input-border)',
                },
              }}
              placeholder="Required"
              padding="13px 8px 13px 16px"
              fullWidth="100%"
              type={showPassword ? 'text' : 'password'}
            />
            {/* eslint-disable jsx-a11y/click-events-have-key-events */}
            {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
            <img
              className="input-icon"
              src={showPassword ? InvisibleImg : VisibleImg}
              onClick={handleClickView}
              alt=""
            />
          </Box>
        </div>

        <div className="modal-footer-customized">
          <Button
            sx={{
              padding: '12px 24px',
              marginRight: 2,
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              width: '101px',
            }}
            variant="outlined"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            sx={{
              padding: '12px 24px',
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              width: '101px',
              backgroundColor: '#CC4525',
              '&:hover': { backgroundColor: '#CC4525' },
            }}
            variant="contained"
            onClick={() => {
              onDelete();
              closeModal();
            }}
          >
            Delete
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

EmailDeleteModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default EmailDeleteModal;
