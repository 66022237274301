import React, {
  useState, useEffect, useContext,
} from 'react';
import {
  Box, Select, MenuItem,
} from '@mui/material';
import AddModal from './AddModal';
import '../settings.css';
import TeamspaceMImg from '../../../images/TeamspaceM.png';
import MemberspaceMImg from '../../../images/MemberspaceM.png';
import personImg from '../../../images/person.svg';
import groupImg from '../../../images/group.svg';
import invisibleImg from '../../../images/invisible.svg';
import visibleImg from '../../../images/visible.svg';
import { sendRequest } from '../../../api/shootAPI';
import API from '../../../api/endpoints';
import { UserContext } from '../../../contexts/User';
import { NotificationContext } from '../../../contexts/Notification';

function TeamVisibilityControls() {
  const {
    displayNotification,
  } = useContext(NotificationContext);
  const { activeWorkspace } = useContext(UserContext);
  const [allTeams, setAllTeams] = useState([]);
  const [allMembers, setAllMembers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const getAllTeams = async () => {
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.getAllTeamInfo(activeWorkspace.id),
      timeout: 60000,
    });

    if (response.success) {
      setAllTeams(response.data.teams);
    }
  };

  const getAllMembers = async () => {
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.members(activeWorkspace.id),
    });
    if (response.success) {
      const mappedmembers = response.data?.members.map((member) => ({
        id: member.id,
        name: member.user?.fullName,
      }));
      setAllMembers(mappedmembers);
    }
  };

  const getTeamVisibilityControls = async (teamId) => {
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.getTeamVisibilityControls(activeWorkspace.id, teamId),
      timeout: 60000,
    });

    if (response.success) {
      setTeams([]);
      setMembers([]);
      response.data?.visibilityControls?.forEach((controlInfo) => {
        if (controlInfo.viewerType === 1) {
          // viewerType is Team
          allTeams.forEach((team) => {
            if (team.id === controlInfo.viewerId) {
              const newTeamInfo = {
                id: teams.length + 1,
                name: team.name,
                accessLevel: controlInfo.accessLevel,
              };
              setTeams((prevTeams) => [...prevTeams, newTeamInfo]);
            }
          });
        } else {
          // viewerType is Member
          allMembers.forEach((member) => {
            if (member.id === controlInfo.viewerId) {
              const newMemberInfo = {
                id: members.length + 1,
                name: member.name,
                accessLevel: controlInfo.accessLevel,
              };
              setMembers((prevMembers) => [...prevMembers, newMemberInfo]);
            }
          });
        }
      });
    }
  };

  const deleteTeamVisibilityControls = async (control) => {
    const response = await sendRequest({
      method: 'delete',
      url: API.WORKSPACE.deleteTeamVisibilityControls(activeWorkspace.id),
      data: { control },
    });

    if (response.success) {
      displayNotification({
        message: 'Delete Team Visibility Control successfully',
        severity: 'success',
      });
      getTeamVisibilityControls(control.teamId);
    } else {
      displayNotification({
        message: 'An error occurs while saving visibility control',
        severity: 'error',
      });
    }
  };

  const setTeamVisibilityControls = async (control) => {
    const response = await sendRequest({
      method: 'post',
      url: API.WORKSPACE.setTeamVisibilityControls(activeWorkspace.id),
      data: { control },
    });

    if (response.success) {
      displayNotification({
        message: 'Create Team Visibility Control successfully',
        severity: 'success',
      });
      getTeamVisibilityControls(control.teamId);
    } else {
      displayNotification({
        message: 'An error occurs while saving visibility control',
        severity: 'error',
      });
    }
  };

  const addViewer = (viewerId, viewerType, accessLevel) => {
    const control = {
      teamId: selectedIndex ? allTeams[selectedIndex - 1].id : 0,
      viewerId,
      viewerType,
      accessLevel,
    };
    setTeamVisibilityControls(control);
  };

  const updateTeamVisibilityControls = async (control) => {
    const response = await sendRequest({
      method: 'put',
      url: API.WORKSPACE.updateTeamVisibilityControls(activeWorkspace.id),
      data: { control },
    });

    if (response.success) {
      displayNotification({
        message: 'Update Team Visibility Control successfully',
        severity: 'success',
      });
      getTeamVisibilityControls(control.teamId);
    } else {
      displayNotification({
        message: 'An error occurs while saving visibility control',
        severity: 'error',
      });
    }
  };

  const handleTeamChanged = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === 'All Team') {
      getTeamVisibilityControls(0);
      setSelectedIndex(0);
    } else {
      const index = allTeams.findIndex((team) => team.name === selectedValue);
      setSelectedIndex(index + 1);
      getTeamVisibilityControls(allTeams[index].id);
    }
  };

  const removeViewer = (viewerId, viewerType) => {
    const control = {
      teamId: selectedIndex ? allTeams[selectedIndex].id : 0,
      viewerId,
      viewerType,
    };
    deleteTeamVisibilityControls(control);
  };

  const handleSelectChanged = (name, viewerType, value) => {
    if (viewerType === 1) {
      teams.map((team) => (team.name === name ? { ...team, accessLevel: value } : team));
      const control = {
        teamId: selectedIndex ? allTeams[selectedIndex - 1].id : 0,
        viewerId: allTeams[allTeams.findIndex((t) => t.name === name)].id,
        viewerType,
        accessLevel: value,
      };
      updateTeamVisibilityControls(control);
    } else {
      members.map((member) => (member.name === name ? { ...member, accessLevel: value } : member));
      const control = {
        teamId: selectedIndex ? allTeams[selectedIndex - 1].id : 0,
        viewerId: allMembers[allMembers.findIndex((m) => m.name === name)].id,
        viewerType,
        accessLevel: value,
      };
      updateTeamVisibilityControls(control);
    }
  };

  useEffect(() => {
    if (allTeams && allMembers) {
      getTeamVisibilityControls(0);
    }
  }, [allTeams, allMembers]);

  useEffect(() => {
    getAllTeams();
    getAllMembers();
  }, []);

  return (
    <Box className="team-visibility-controls">
      <Box className="body-header">
        <Box className="texts">
          <Box className="title">
            Configure Team Visibility
          </Box>
          <Box className="sub-title">
            Define who can view specific teams and members.
          </Box>
        </Box>
        <Select
          className="select"
          defaultValue="All Team"
          onChange={handleTeamChanged}
        >
          <MenuItem className="menu-item" key={0} value="All Team">
            All Team
          </MenuItem>
          {allTeams && allTeams.map((team) => (
            <MenuItem className="menu-item" key={team.id} value={team.name}>
              {team.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box className="body-main">
        <Box className="sub-option">
          <Box className="option-header">
            <Box className="group">
              <img className="image" src={TeamspaceMImg} alt="" />
              <Box className="title">
                Team-Level Visibility
              </Box>
            </Box>
            <AddModal viewerType={1} viewers={allTeams} onSaved={addViewer} />
          </Box>
          <Box className="option-body">
            {teams && teams.map((team) => (
              <Box className="option">
                <Box className="group">
                  <img className={!team.status ? 'image' : 'image disabled'} src={groupImg} alt="" />
                  <Box className={!team.status ? 'name' : 'name disabled'}>
                    {team.name}
                  </Box>
                </Box>
                <Box className="group">
                  <Select
                    className={!team.status ? 'select' : 'select disabled'}
                    value={team.accessLevel}
                  >
                    <MenuItem
                      className="menu-item"
                      value={0}
                      onClick={() => {
                        handleSelectChanged(team.name, 1, 0);
                      }}
                    >
                      Full Access
                    </MenuItem>
                    <MenuItem
                      className="menu-item"
                      value={1}
                      onClick={() => {
                        handleSelectChanged(team.name, 1, 1);
                      }}
                    >
                      Limited Access
                    </MenuItem>
                    <MenuItem
                      className="menu-item danger"
                      value={2}
                      onClick={() => {
                        removeViewer(allTeams[allTeams.findIndex((t) => t.name === team.name)].id, 1);
                      }}
                    >
                      Remove
                    </MenuItem>
                  </Select>
                  <img
                    className="image"
                    src={!team.status ? invisibleImg : visibleImg}
                    // onClick={() => toggleTeamStatus(team.id)}
                    alt=""
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <Box className="sub-option">
          <Box className="option-header">
            <Box className="group">
              <img className="image" src={MemberspaceMImg} alt="" />
              <Box className="title">
                Individual Member Access
              </Box>
            </Box>
            <AddModal viewerType={2} viewers={allMembers} onSaved={addViewer} />
          </Box>
          <Box className="option-body">
            {members && members.map((member) => (
              <Box className="option">
                <Box className="group">
                  <img className={!member.status ? 'image' : 'image disabled'} src={personImg} alt="" />
                  <Box className={!member.status ? 'name' : 'name disabled'}>
                    {member.name}
                  </Box>
                </Box>
                <Box className="group">
                  <Select
                    className={!member.status ? 'select' : 'select disabled'}
                    value={member.accessLevel}
                  >
                    <MenuItem
                      className="menu-item"
                      value={0}
                      onClick={() => {
                        handleSelectChanged(member.name, 2, 0);
                      }}
                    >
                      Full Access
                    </MenuItem>
                    <MenuItem
                      className="menu-item"
                      value={1}
                      onClick={() => {
                        handleSelectChanged(member.name, 2, 1);
                      }}
                    >
                      Limited Access
                    </MenuItem>
                    <MenuItem
                      className="menu-item danger"
                      value={2}
                      onClick={() => {
                        removeViewer(allMembers[allMembers.findIndex((m) => m.name === member.name)].id, 2);
                      }}
                    >
                      Remove
                    </MenuItem>
                  </Select>
                  <img
                    className="image"
                    src={!member.status ? invisibleImg : visibleImg}
                    // onClick={() => toggleMemberStatus(member.id)}
                    alt=""
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default TeamVisibilityControls;
